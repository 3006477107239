import { cities } from './dummyData';
const secretKey = 'crmFourSeason1234'; // Replace with your secret key

export const getCityNameById = cityId => {
  const city = cities.find(city => city.city_id === cityId);
  return city ? city.name : 'Unknown City';
};

export const encryptId = id => {
  const encryptedId = id
    .toString()
    .split('')
    .map(char => {
      const charCode = char.charCodeAt(0);
      const encryptedCharCode = charCode * 7 + secretKey.length;
      return String.fromCharCode(encryptedCharCode);
    })
    .join('');
  return encryptedId;
};

export const decryptId = encryptedId => {
  const decryptedId = encryptedId
    .split('')
    .map(char => {
      const charCode = char.charCodeAt(0);
      const decryptedCharCode = (charCode - secretKey.length) / 7;
      return String.fromCharCode(decryptedCharCode);
    })
    .join('');
  return parseInt(decryptedId, 10);
};

export const beautifyDate = (dateString, includeTime = true) => {
  // Define options for formatting the date
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // If includeTime is true, add time options
  if (includeTime) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.hour12 = true; // 12-hour format
  }

  const date = new Date(dateString);

  // Check for an invalid date or if the year is before 2010, return an empty string
  if (isNaN(date.getTime()) || date.getFullYear() < 2010) {
    return '';
  }

  // Format and return the date
  return date.toLocaleString('en-US', options);
};

export const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);

  // Check if the year is before 2010, return an empty string if true
  if (date.getFullYear() < 2010) {
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero
  const day = String(date.getDate()).padStart(2, '0'); // Add leading zero
  return `${year}-${month}-${day}`;
};
