import {
  Button,
  Center,
  Heading,
  Icon,
  Stack,
  Image,
  Text,
  Flex,
  useToast,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { FaUpload } from 'react-icons/fa';
import { useBgColorChild } from '../../../utils/colors';
import { BiSave } from 'react-icons/bi';
import { updateCertificate } from '../../../api/certificatesApi';

const UploadCertificate = ({ selectedItem, onSave, onClose }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);

      // Create a preview URL for the file
      const previewUrl = URL.createObjectURL(selectedFile);
      setFilePreview(previewUrl);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const toast = useToast();
  const handleSave = async () => {
    if (!file) {
      toast({
        title: 'Error uploading certificate.',
        description: 'Please select a file to upload.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('image', file);
    setBtnLoading(true);

    try {
      const response = await updateCertificate(selectedItem.id, formData);
      setFile(null);
      setFilePreview(null);
      onSave(response.updatedCertificate, 'edit').finally(() => {
        toast({
          title: 'Certificate Uploaded.',
          description: 'The certificate has been uploaded successfully.',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        setBtnLoading(false);
        onClose();
      });
    } catch (error) {
      toast({
        title: 'Error uploading certificate.',
        description:
          error?.response?.data?.message ||
          'There was an error uploading the certificate. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(false);
    }
  };

  return (
    <Center bg={useBgColorChild} borderRadius="lg" minH="80vh">
      <Stack align="center" gap={2}>
        <Heading>Upload Certificate</Heading>
        <Icon as={FaUpload} boxSize="100px" />
        {file && (
          <>
            <Text>File selected: {file.name}</Text>
            {file.type.startsWith('image/') ? (
              <Image src={filePreview} alt="File Preview" maxH="300px" />
            ) : (
              <a href={filePreview} target="_blank" rel="noopener noreferrer">
                Preview PDF
              </a>
            )}
          </>
        )}
        <Flex gap={2}>
          <Button colorScheme="green" onClick={handleButtonClick}>
            {file ? 'Upload Again' : 'Upload File'}
          </Button>
          <Button
            colorScheme="green"
            leftIcon={<BiSave />}
            onClick={handleSave}
          >
            Save
          </Button>
        </Flex>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".pdf,image/*"
          onChange={handleFileChange}
        />
      </Stack>
    </Center>
  );
};

export default UploadCertificate;
