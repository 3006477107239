import React from 'react';
import Page from './Page';
import { Button, Center, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { useBgColor, useBgColorChild } from '../../utils/colors';
import { Link } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';

const NotFound = ({ sideBarWidth }) => {
  return (
    <Page sideBarWidth={sideBarWidth}>
      <Stack
        bg={useBgColorChild}
        minH="60vh"
        borderRadius="lg"
        justify="center"
        align="center"
      >
        <Heading>Error 404</Heading>
        <Text>Page Not Found</Text>
        <Button
          as={Link}
          to={-1}
          variant="solid"
          colorScheme="green"
          leftIcon={<ArrowBackIcon />}
          my={10}
        >
          Back
        </Button>
      </Stack>
    </Page>
  );
};

export default NotFound;
