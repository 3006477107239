import { BASE_URL, getTokenFromLocalStorage, handleUnauthorizedError } from "./constants";
import axios from 'axios';

export const createCertificate = async (formData) => {
    try {
        const response = await axios.post(`${BASE_URL}/certificate`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        handleUnauthorizedError(error)
        throw error;
    }
};

export const getCertificates = async (page, limit, reg_no, district_name, status) => {
    try {
        const response = await axios.get(`${BASE_URL}/certificate/totalItems`, {
            params: {
                reg_no,
                district_name,
                status,
            },
            headers: {
                token: getTokenFromLocalStorage(),
            }
        });
        const totalItems = response.data.totalItems;

        

        const dataResponse = await axios.get(`${BASE_URL}/certificate`, {
            params: {
                page: page,
                limit: limit,
                reg_no,
                district_name,
                status,
            },
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return {
            data: dataResponse.data,
            totalPages: Math.ceil(totalItems / limit),
        };
    } catch (error) {
        console.error('Error fetching certificates:', error);
        handleUnauthorizedError(error)
        return {data:[],totalPages:0};
    }
};


export const deleteCertificate = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/certificate/${id}`, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting certificate:', error);
        handleUnauthorizedError(error)
        throw error;
    }
};


export const updateCertificate = async (id, formData) => {
    try {
        const response = await axios.put(`${BASE_URL}/certificate/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        handleUnauthorizedError(error)
        throw error; // Throw error to handle it in the component
    }
};

export const fetchImageOrPdf = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/certificate/image-or-pdf/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching image or PDF:', error);
        handleUnauthorizedError(error)
        throw new Error('Failed to fetch image or PDF');
    }
};