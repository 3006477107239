
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Page from '../../../component/Layout/Page';
import ViewPdf from './ViewPdf';
import { useBgColor, useBgColorChild } from '../../../utils/colors';
import { BiChevronLeft, BiChevronRight, BiSearch } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';

const FilesList = ({ sideBarWidth }) => {
  const [pdfRecords, setPdfRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const { year } = useParams();

  useEffect(() => {
    // Fetch paths.json from the public directory
    fetch('/paths.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const files = data[year] || [];
        setPdfRecords(files);
      })
      .catch(error => console.error('Error fetching paths:', error));
  }, [year]);

  const handleRowClick = url => {
    setSelectedPdf(url);
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value.toLowerCase());
    setCurrentPage(1); // Reset to the first page on new search
  };

  const filteredRecords = pdfRecords.filter(item =>
    item.fileName.toLowerCase().includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredRecords.length / itemsPerPage);

  const currentItems = filteredRecords.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  return (
    <Page sideBarWidth={sideBarWidth}>
      <Box>
        {selectedPdf ? (
          <ViewPdf pdfUrl={selectedPdf} onClose={() => setSelectedPdf(null)} />
        ) : (
          <Box bg={useBgColorChild} borderRadius="lg" px={2} py={2} shadow="xl">
            <Flex justify="space-between" my={4}>
              <Flex align="center" w="50%">
                <InputGroup w="100%" size={'sm'}>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.400"
                    fontSize="1.2em"
                    ml={2}
                  >
                    <BiSearch />
                  </InputLeftElement>
                  <Input
                    placeholder="Search by file name"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    borderRadius="0.3rem"
                    py={2}
                    pl={10}
                    pr={3}
                    fontSize="md"
                    mr={4}
                    _placeholder={{ color: 'gray.400' }}
                    bg={useBgColor}
                  />
                </InputGroup>
              </Flex>
              <Button
                colorScheme="green"
                as={Link}
                to={-1}
                leftIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </Flex>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Date</Th>
                    <Th>Size</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItems.map((item, index) => (
                    <Tr
                      key={index}
                      _hover={{
                        transition: 'transform 0.2s ease-in-out',
                        cursor: 'pointer',
                        fontWeight: 'semibold',
                      }}
                      onClick={() => handleRowClick(item.url)}
                    >
                      <Td>{item.fileName}</Td>
                      <Td>{item.date}</Td>
                      <Td>{item.size}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex justify="space-between" mt={4} align="center">
              <Box>
                <IconButton
                  icon={<BiChevronLeft />}
                  isDisabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  aria-label="Previous Page"
                />
                <IconButton
                  icon={<BiChevronRight />}
                  isDisabled={currentPage >= totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  ml={2}
                  aria-label="Next Page"
                />
              </Box>
              <Text fontSize="smaller">
                Page {currentPage} of {totalPages}
              </Text>
            </Flex>
          </Box>
        )}
      </Box>
    </Page>
  );
};

export default FilesList;
