import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useBgColorChild } from '../../../utils/colors';
import MyBox from '../../../component/Layout/MyBox';
import { cities } from '../../../utils/dummyData';
import { getCityNameById } from '../../../utils/funtions';

const Show = ({ selectedItem }) => {
  return (
    <Stack>
      <MyBox>
        <Text fontWeight="semibold">Id</Text>
        <Text fontWeight="semibold">{selectedItem.city_id}</Text>
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Name</Text>
        <Text fontWeight="semibold">{selectedItem.name}</Text>
      </MyBox>
     
    </Stack>
  );
};

export default Show;
