import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import Add from './Add';
import Show from './Show';
import Edit from './Edit';

const Drawers = ({
  isOpen,
  onClose,
  drawerType,
  data,
  onSave, // Renamed function
}) => {
  const drawerSize = 'lg';
  const renderDrawer = () => {
    switch (drawerType) {
      case 'addNew':
        return (
          <Add
          onSave={onSave} // Renamed function
          onClose={onClose}
          />
        );
      case 'show':
        return <Show selectedItem={data} onClose={onClose} />;
      case 'edit':
        return (
          <Edit
            selectedItem={data}
            onClose={onClose}
            onSave={onSave} // Renamed function
          />
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size={drawerSize}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent >
        <DrawerHeader>
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={onClose}
            variant="ghost"
            alignItems="center"
            justifyContent="center"
          />
          {drawerType === 'addNew' && 'Add New User'} {/* Updated text */}
          {drawerType === 'show' && 'Show User Details'} {/* Updated text */}
          {drawerType === 'edit' && 'Edit User Details'} {/* Updated text */}
        </DrawerHeader>
        <DrawerBody>{renderDrawer()}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Drawers;
