import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const ViewPdf = ({ pdfUrl, onClose }) => {
  return (
    <>
      <Button
        colorScheme="green"
        leftIcon={<ArrowBackIcon />}
        onClick={onClose}
        mb={2}
      >
        Back
      </Button>
      <Box
        width="100%"
        height="100vh" // Set height to full viewport height
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <iframe
          title="PDF Preview"
          src={pdfUrl} // Use the pdfUrl prop
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </Box>
    </>
  );
};

export default ViewPdf;
