import axios from "axios";
import { BASE_URL, getTokenFromLocalStorage } from "./constants";

// Function to fetch districts data based on query parameters
export const fetchDistricts = async (page = 1, limit = 10, name = '') => {
    try {
        const countResponse = await axios.get(`${BASE_URL}/districts/totalItems`, {
            params: {
                ...(name && { name: name }),
            },
            headers: {
                token: getTokenFromLocalStorage(),
            }
        });
        const totalItems = countResponse.data.totalItems;
        const response = await axios.get(`${BASE_URL}/districts`, {
            params: { page, limit, name },
            headers: {
                token: getTokenFromLocalStorage(),
            },
        },);
        // Return the data from the response
        return { data: response.data, totalPages: Math.ceil(totalItems / limit), };
    } catch (error) {
        // Handle errors or return default value
        console.error('Error fetching districts:', error);
        return { data: [], totalPages: 0 };
    }
};

export const addDistrict = async (districtData) => {
    try {
        const response = await axios.post(`${BASE_URL}/districts`, districtData, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error adding district:', error);
        return null;
    }
};

export const updateDistrict = async (id, updatedData) => {
    try {
        const response = await axios.put(`${BASE_URL}/districts/${id}`, updatedData, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteDistrict = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/districts/${id}`, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};