import { jsPDF } from 'jspdf';


function createPDF(qrCode) {
    const doc = new jsPDF({
      orientation: 'landscape', // Set the orientation to landscape
      unit: 'mm', // Set the measurement unit to millimeters
      format: 'a4', // Set the page format to A4
      compress: true, // Enable compression
    });
    // doc.addFont('ComicSansMS', 'Comic Sans', 'normal');
    const width = doc.internal.pageSize.width;
    const height = doc.internal.pageSize.height;
    // const name = data?.name;
  
    // doc.setFont('helvetica', 'bold');
    let textY = 23;
    let leftPadding = 23;
  
    doc.addImage(qrCode, 'PNG', width - 90, 55, 25, 25, '', 'FAST');
  
    // Save the PDF
    //   doc.save('Disability_Assessment_Certificate.pdf');
    return doc;
  }


export const generateCertificateQR = (qrCode) => {
    const doc = createPDF(qrCode);
    doc.save('Test-transparent');
  };
  
  export const printPdfQR = (qrCode) => {
    const doc = createPDF(qrCode);
  
    // Create a Blob from the PDF
    const pdfBlob = doc.output('blob');
  
    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);
  
    // Open a new window for printing
    const printWindow = window.open(pdfUrl, '_blank');
  
    // Wait for the PDF to load before printing
    printWindow.onload = () => {
      printWindow.print();
      // Clean up after printing
      // URL.revokeObjectURL(pdfUrl);
    };
    return pdfBlob;
  };