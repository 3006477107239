//Dev BASE_URL
// export const BASE_URL = 'http://localhost:3000'
// export const BASE_FRONTEND_URL = 'http://localhost:3001'
// PRODUCTION COGNISOFT 
 export const BASE_URL = 'https://qrbackend.cognisoftlabs.com'
 export const BASE_FRONTEND_URL = 'https://qr.cognisoftlabs.com'

export function getTokenFromLocalStorage() {
    const token = localStorage.getItem('token');
    return token;
  }
  
  export function getIDFromLocalStorage() {
    const id = localStorage.getItem('id');
    return id;
  }
  export const handleUnauthorizedError = (error) => {
    if (error.response && error.response.data && error.response.data.message === 'Unauthorized') {

    localStorage.clear();
    window.location.href = '/';
    }
};
// export const role = localStorage.getItem("role");
export const role = "Admin";
export const Name = localStorage.getItem("contactPerson");
export const branchId = localStorage.getItem("branchId");
export const branchName = localStorage.getItem("branchName");