import React, { useEffect, useState } from 'react';
import MyBox from '../../../component/Layout/MyBox';
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import Drawers from './Drawers';
import { useBgColor } from '../../../utils/colors';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { BiSearch, BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { PiCertificateLight } from 'react-icons/pi';
import DeleteAlert from '../../../component/DeleteAlert';
import {
  deleteCertificate,
  getCertificates,
} from '../../../api/certificatesApi';
import Loader from '../../../component/Loader/Loader';
import { Link } from 'react-router-dom';
import { BASE_FRONTEND_URL } from '../../../api/constants';
import { encryptId } from '../../../utils/funtions';

const Layout = () => {
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Define isDrawerOpen state
  const [selectedDrawerType, setSelectedDrawerType] = useState('');
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [certificates, setCertificates] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;

  const toast = useToast();

  useEffect(() => {
    const fetchCerficates = async () => {
      setIsLoading(true);
      try {
        const page = currentPage;
        const limit = itemsPerPage;
        const reg_no = searchTerm;
        const district_name = searchTerm;
        const status = statusFilter;

        const data = await getCertificates(
          page,
          limit,
          reg_no,
          district_name,
          status === 'all' ? '' : status
        );
        setCertificates(data.data);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching certificates:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCerficates();
  }, [searchTerm, currentPage, statusFilter]);

  const handleSearchChange = event => {
    const searchText = event.target.value.toLowerCase();
    setSearchTerm(searchText);
  };
  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };
  const handleStatusChange = e => {
    setStatusFilter(e.target.value);
  };

  const openDrawer = (drawerType, itemData) => {
    setSelectedDrawerType(drawerType);
    setSelectedItemData(itemData);
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedDrawerType('');
    setSelectedItemData(null);
  };
  const handleDeleteClick = (id, name) => {
    setSelectedItemId(id);
    setSelectedItemName(name);
    setIsDeleteAlertOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteCertificate(selectedItemId); // Delete certificate by ID
      setIsDeleteAlertOpen(false);
      toast({
        title: 'Certificate deleted.',
        description: 'The certificate has been deleted successfully.',
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });

      // Remove the deleted certificate from the list
      setCertificates(prevCertificates =>
        prevCertificates.filter(
          certificate => certificate.id !== selectedItemId
        )
      );
    } catch (error) {
      console.error('Error deleting certificate:', error);
      toast({
        title: 'Error deleting certificate.',
        description:
          error.response.data.message ||
          'There was an error deleting the certificate. Please try again.',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Empty dependency array means this effect runs once when the component mounts
  const handleAddUpdateCertificate = async (certificateData, type) => {
    if (type === 'add') {
      setCertificates(prevCertificates => [
        ...prevCertificates,
        certificateData,
      ]);
    } else if (type === 'edit') {
      setCertificates(prevCertificates =>
        prevCertificates.map(certificate =>
          certificate.id === certificateData.id
            ? { ...certificate, ...certificateData }
            : certificate
        )
      );
    }
    setCurrentPage(1); // Optionally reset to the first page
  };
  // if (isLoading) {
  //   return <Loader />;
  // }
  return (
    <MyBox shadow="xl">
      <Flex justify="space-between" my={4}>
        <Flex align="center" w="50%">
          <InputGroup w="100%" size={'sm'}>
            <InputLeftElement
              pointerEvents="none"
              color="gray.400"
              fontSize="1.2em"
              ml={2}
            >
              <BiSearch />
            </InputLeftElement>
            <Input
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={handleSearchChange}
              borderRadius="0.3rem"
              py={2}
              pl={10}
              pr={3}
              fontSize="md"
              mr={4}
              _placeholder={{ color: 'gray.400' }}
              bg={useBgColor}
            />
          </InputGroup>

          <Select
            // placeholder="All"
            onChange={handleStatusChange}
            width="200px"
            size="sm"
            ml={4}
            borderRadius="lg"
            bg={useBgColor}
          >
            <option value="all">All</option>
            <option value="uploaded">Uploaded</option>
            <option value="not uploaded">Not Uploaded</option>
          </Select>
        </Flex>
        <Button
          onClick={() => {
            openDrawer('addNew');
          }}
          colorScheme="green"
        >
          Add New
        </Button>
      </Flex>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Registration No</Th>
                  <Th>District</Th>
                  <Th>Link</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {certificates?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item?.reg_no}</Td>
                    <Td>{item?.district_name}</Td>
                    <Td>
                      <Link
                        to={`${BASE_FRONTEND_URL}/certificate-details/${encryptId(
                          item.id
                        )}`}
                      >
                        View
                      </Link>
                    </Td>
                    <Td>
                      <Badge
                        variant="solid"
                        colorScheme={
                          item?.scanned_certificate_status == 'uploaded'
                            ? 'green'
                            : 'orange'
                        }
                        borderRadius="md"
                      >
                        {item?.scanned_certificate_status}
                      </Badge>
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<HiOutlineDotsVertical />}
                          variant="ghost"
                          size="sm"
                        />
                        <MenuList>
                          <MenuItem
                            icon={<FiEdit />}
                            onClick={() => openDrawer('edit', item)}
                          >
                            Edit
                          </MenuItem>
                          {item?.scanned_certificate_status ==
                            'not uploaded' && (
                            <MenuItem
                              icon={<PiCertificateLight />}
                              onClick={() => openDrawer('upload', item)}
                            >
                              Upload Certificate
                            </MenuItem>
                          )}
                          <MenuItem
                            icon={<FiTrash2 />}
                            onClick={() =>
                              handleDeleteClick(item.id, item.name)
                            }
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
      <Flex justify="space-between" mt={4} align="center">
        <Box>
          <Box>
            <IconButton
              icon={<BiChevronLeft />}
              isDisabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              aria-label="Previous Page"
            />
            <IconButton
              icon={<BiChevronRight />}
              isDisabled={currentPage >= totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              ml={2}
              aria-label="Next Page"
            />
          </Box>
        </Box>
        <Text fontSize="smaller">
          Page {currentPage} of {totalPages}
        </Text>
      </Flex>

      <Drawers
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        drawerType={selectedDrawerType}
        data={selectedItemData}
        onSave={handleAddUpdateCertificate}
      />
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onConfirmDelete={handleConfirmDelete}
        HeaderText={'Delete Certificate'}
        BodyText={`Are you sure you want to delete ${selectedItemName} ?`}
      />
    </MyBox>
  );
};

export default Layout;
