import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useBgColor, useBgColorChild } from '../../../utils/colors';
import { getUsers, createUser, deleteUser } from '../../../api/userApi';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { FaRegEye } from 'react-icons/fa';
import { BiSearch, BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import Drawers from './Drawers';
import DeleteAlert from '../../../component/DeleteAlert';
import { getIDFromLocalStorage } from '../../../api/constants';
import Loader from '../../../component/Loader/Loader';

const UserList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [users, setUsers] = useState([]);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDrawerType, setSelectedDrawerType] = useState('');
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const indexOfLastItem = currentPage * itemsPerPage;
  const toast = useToast();
  const loginUserId = getIDFromLocalStorage();
  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const role = 'employee'; // Adjust as needed
        const email = searchTerm;
        const name = searchTerm;
        const page = currentPage;
        const limit = itemsPerPage;

        const data = await getUsers(role, email, name, page, limit);
        setUsers(data.data);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [searchTerm, currentPage]);

  const handleSearchChange = event => {
    const searchText = event.target.value.toLowerCase();
    setSearchTerm(searchText);
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const openDrawer = (drawerType, itemData) => {
    setSelectedDrawerType(drawerType);
    setSelectedItemData(itemData);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedDrawerType('');
    setSelectedItemData(null);
  };

  const handleDeleteClick = (id, name) => {
    setSelectedItemId(id);
    setSelectedItemName(name);
    setIsDeleteAlertOpen(true);
  };

  const handleAddUser = async (userData, type) => {
    if (type === 'add') {
      setUsers(prevUsers => [...prevUsers, userData]);
      setCurrentPage(1); // Optionally reset to the first page
    }
    if (type === 'edit') {
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === userData.id ? { ...user, ...userData } : user
        )
      );
      setCurrentPage(1); // Optionally reset to the first page
    }
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser(selectedItemId); // Delete user by ID
      setUsers(prevUsers =>
        prevUsers.filter(user => user.id !== selectedItemId)
      );
      setIsDeleteAlertOpen(false);
      toast({
        title: 'User deleted.',
        description: 'The user has been deleted successfully.',
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      toast({
        title: 'Error deleting user.',
        description:
          error.response.data.message ||
          'There was an error deleting the user. Please try again.',
        status: 'error',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <Box bg={useBgColorChild} borderRadius="lg" px={2} py={2} shadow="xl">
      <Flex justify="space-between" my={4}>
        <Flex align="center" w="50%">
          <InputGroup w="100%" size={'sm'}>
            <InputLeftElement
              pointerEvents="none"
              color="gray.400"
              fontSize="1.2em"
              ml={2}
            >
              <BiSearch />
            </InputLeftElement>
            <Input
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={handleSearchChange}
              borderRadius="0.3rem"
              py={2}
              pl={10}
              pr={3}
              fontSize="md"
              mr={4}
              _placeholder={{ color: 'gray.400' }}
              bg={useBgColor}
            />
          </InputGroup>
        </Flex>
        <Button colorScheme="green" onClick={() => openDrawer('addNew')}>
          Add User
        </Button>
      </Flex>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Id</Th>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Contact</Th>
                  <Th>Role</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map(
                  (item, index) =>
                    loginUserId !== item.id && (
                      <Tr key={item.id}>
                        <Td>{index + 1}</Td>
                        <Td>{item.fullname}</Td>
                        <Td>{item.email}</Td>
                        <Td>{item.contact_number}</Td>
                        <Td>{item.role}</Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<HiOutlineDotsVertical />}
                              variant="ghost"
                              size="sm"
                            />
                            <MenuList>
                              <MenuItem
                                icon={<FaRegEye />}
                                onClick={() => openDrawer('show', item)}
                              >
                                Show
                              </MenuItem>
                              <MenuItem
                                icon={<FiEdit />}
                                onClick={() => openDrawer('edit', item)}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                icon={<FiTrash2 />}
                                onClick={() =>
                                  handleDeleteClick(item.id, item.fullname)
                                }
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    )
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
      <Flex justify="space-between" mt={4} align="center">
        <Box>
          <IconButton
            icon={<BiChevronLeft />}
            isDisabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            aria-label="Previous Page"
          />
          <IconButton
            icon={<BiChevronRight />}
            isDisabled={currentPage >= totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            ml={2}
            aria-label="Next Page"
          />
        </Box>
        <Text fontSize="smaller">
          Page {currentPage} of {totalPages}
        </Text>
      </Flex>
      <Drawers
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        drawerType={selectedDrawerType}
        data={selectedItemData}
        onSave={handleAddUser}
      />
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onConfirmDelete={handleDeleteUser}
        HeaderText={'Delete User'}
        BodyText={`Are you sure you want to delete ${selectedItemName}?`}
      />
    </Box>
  );
};

export default UserList;
