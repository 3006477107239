import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Text,
  Input,
  VStack,
  Select,
  Button,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  Flex,
  Spacer,
  useToast,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import MyBox from '../../../component/Layout/MyBox';
import { cities, districts } from '../../../utils/dummyData';
import { useBgColorChild } from '../../../utils/colors';
import {
  createPDF,
  generateCertificate,
  printPdf,
} from '../../../utils/certificateGen';
import {
  generateCertificateTransparent,
  printPdfTransparent,
} from '../../../utils/centificateGenTransparent';
import { updateCertificate } from '../../../api/certificatesApi';
import { fetchDistricts } from '../../../api/districtsApi';
import QR from 'qrcode-base64';
import { encryptId, formatDateForInput } from '../../../utils/funtions';
import { BASE_FRONTEND_URL } from '../../../api/constants';
import { printPdfQR } from '../../../utils/addQR';
import { MdOutlineQrCode2 } from 'react-icons/md';

const Edit = ({ selectedItem, onClose, onSave }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const qrCodeRef = useRef(null);

  const [btnLoading, setBtnLoading] = useState(false);
  const [searchTermItems, setSearchTermItems] = useState('');
  const [formValues, setFormValues] = useState({
    id: selectedItem?.id,
    district: selectedItem?.district_name,
    date_of_issue: formatDateForInput(selectedItem?.date_of_issue) || '',
    date_of_birth: formatDateForInput(selectedItem?.date_of_birth) || '',
    serial_no: selectedItem?.serial_no,
    reg_no: selectedItem?.reg_no,
    name: selectedItem?.name,
    childO: selectedItem?.childO,
    cnic: selectedItem?.cnic,
    education: selectedItem?.education,
    domicile: selectedItem?.domicile,
    religion: selectedItem?.religion,
    address: selectedItem?.address,
    contactNo: selectedItem?.contactNo,
    email: selectedItem?.email,
    pd1: selectedItem?.pd1,
    pd2: selectedItem?.pd2,
    pd3: selectedItem?.pd3,
    pd4: selectedItem?.pd4,
    pd5: selectedItem?.pd5,
    pd6: selectedItem?.pd6,
    pd7: selectedItem?.pd7,
    pd8: selectedItem?.pd8,
    pd9: selectedItem?.pd9,
    vi1: selectedItem?.vi1,
    vi2: selectedItem?.vi2,
    hi1: selectedItem?.hi1,
    hi2: selectedItem?.hi2,
    hi3: selectedItem?.hi3,
    nd1: selectedItem?.nd1,
    nd2: selectedItem?.nd2,
    nd3: selectedItem?.nd3,
    nd4: selectedItem?.nd4,
    nd5: selectedItem?.nd5,
    nd6: selectedItem?.nd6,
    cn1: selectedItem?.cn1,
    cn2: selectedItem?.cn2,
    cn3: selectedItem?.cn3,
    md: selectedItem?.md,
    cd1: selectedItem?.cd1,
    cd2: selectedItem?.cd2,
    cd3: selectedItem?.cd3,
    extentOfDisability: selectedItem?.extentOfDisability,
    district_id: selectedItem?.district_id,
  });
  const [searchTerm, setSearchTerm] = useState(formValues.district);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    const getDistricts = async () => {
      try {
        const data = await fetchDistricts(1, 10, searchTerm);
        setDistricts(data.data);
      } catch (error) {
        console.error('Error fetching certificates:', error);
      }
    };
    getDistricts();
  }, [searchTerm]);

  const handleChange = (name, value) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const toast = useToast();
  const [url, setUrl] = useState(
    `${BASE_FRONTEND_URL}/certificate-details/${encryptId(selectedItem.id)}}`
  );

  const handleSave = async (data, throwError) => {
    try {
      // Generate the QR code
      // generateQrCode();
      setBtnLoading(true);

      // Generate the PDF and get the blob
      // const pdfBlob = returnPdf(data, qrCode);

      // Create a FormData object
      const formData = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      formData.delete('district');
      formData.delete('id');

      // formData.append('image', pdfBlob);

      // Call the API to create the certificate
      const response = await updateCertificate(data.id, formData);

      toast({
        title: 'Certificate updated.',
        description: 'The certificate has been updated successfully.',
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });

      onSave(response.updatedCertificate, 'edit').finally(() => {
        setBtnLoading(false);
        // onClose(); // Close the drawer after save
      });
      // handleAddUpdateDeleteItem();
    } catch (error) {
      console.error('Error updating certificate:', error);
      toast({
        title: 'Error updating certificate.',
        description:
          error?.response?.data?.message ||
          'There was an error updating the certificate. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(false);
      if (throwError) {
        throw error;
      }
    }
  };
  let qrCode = '';

  const generateQrCode = url => {
    var imgData = QR.drawImg(url, {
      typeNumber: 5,
      errorCorrectLevel: 'M',
      size: 150,
    });
    return imgData;
  };
  const handlePdfDownload = async data => {
    try {
      await handleSave(formValues, true);
      const qrcode = await generateQrCode(url);
      printPdf(data, qrcode);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handlePdfDownloadTransparent = async data => {
    try {
      await handleSave(formValues, true);
      const qrcode = await generateQrCode(url);
      printPdfTransparent(data, qrcode);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  const handlePdfQRAdd = async data => {
    try {
      await handleSave(formValues, true);
      const qrcode = await generateQrCode(url);
      printPdfQR(qrcode);
      onClose();
    } catch (error) {
      console.error('Error creating certificate:', error);
    }
  };
  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={2}>
        <MyBox>
          <FormControl isRequired>
            <FormLabel fontWeight="semibold">District</FormLabel>
            <Box>
              <Input
                name="district"
                placeholder="Search district"
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value);
                }}
              />
              {districts && districts.length > 0 && (
                <VStack
                  align="start"
                  spacing={2}
                  borderWidth="1px"
                  borderColor="gray.200"
                  borderRadius="md"
                  mt={1}
                >
                  {districts.map((result, index) => {
                    if (
                      districts.length === 1 &&
                      result.id === formValues.district_id
                    ) {
                      return null; // Skip rendering if the district has only one item and its ID matches formValues.district_id
                    }
                    return (
                      <Box
                        key={index}
                        p={2}
                        bg={useBgColorChild}
                        borderWidth="1px"
                        borderColor="gray.200"
                        borderRadius="md"
                        cursor="pointer"
                        onClick={() => {
                          setSearchTerm(result.name);
                          handleChange('district_id', result.id);
                          handleChange('district', result.name);
                        }}
                      >
                        {result.name}
                      </Box>
                    );
                  })}
                </VStack>
              )}
            </Box>
          </FormControl>
        </MyBox>
        <MyBox>
          <FormControl isRequired>
            <FormLabel fontWeight="semibold">Registration No.</FormLabel>
            <Input
              name="reg_no"
              value={formValues.reg_no}
              onChange={e => handleChange('reg_no', e.target.value)}
            />
          </FormControl>
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">DATE OF ISSUE</Text>
          <Input
            name="date_of_issue"
            value={formatDateForInput(formValues.date_of_issue)}
            type="date"
            onChange={e => {
              handleChange('date_of_issue', e.target.value);
            }}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">SERIAL No</Text>
          <Input
            name="serial_no"
            value={formValues.serial_no}
            onChange={e => handleChange('serial_no', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Name</Text>
          <Input
            name="name"
            value={formValues.name}
            onChange={e => handleChange('name', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">S/O, D/O, W/O</Text>
          <Input
            name="childO"
            value={formValues.childO}
            onChange={e => handleChange('childO', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">DATE OF BIRTH:</Text>
          <Input
            name="date_of_birth"
            value={formValues.date_of_birth}
            type="date"
            onChange={e => handleChange('date_of_birth', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">SCNIC#/SNICOP#:</Text>
          <Input
            name="cnic"
            value={formValues.cnic}
            onChange={e => handleChange('cnic', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">EDUCATIONAL QUALIFICATION:</Text>
          <Input
            name="education"
            value={formValues.education}
            onChange={e => handleChange('education', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">DOMICILE:</Text>
          <Input
            name="domicile"
            value={formValues.domicile}
            onChange={e => handleChange('domicile', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">RELIGION:</Text>
          <Input
            name="religion"
            value={formValues.religion}
            onChange={e => handleChange('religion', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">POSTAL ADDRESS:</Text>
          <Input
            name="address"
            value={formValues.address}
            onChange={e => handleChange('address', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">CONTACT NO.:</Text>
          <Input
            name="contactNo"
            value={formValues.contactNo}
            onChange={e => handleChange('contactNo', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">EMAIL:</Text>
          <Input
            name="email"
            value={formValues.email}
            onChange={e => handleChange('email', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Extent of Disability:</Text>
          <Input
            name="extentOfDisability"
            value={formValues.extentOfDisability}
            onChange={e => handleChange('extentOfDisability', e.target.value)}
          />
        </MyBox>
      </SimpleGrid>

      <MyBox my={4}>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Physical Disabilities
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox
                  isChecked={formValues.pd1}
                  onChange={() => handleChange('pd1', !formValues.pd1)}
                >
                  Locomotor Disability
                </Checkbox>
                <Checkbox
                  isChecked={formValues.pd2}
                  onChange={() => handleChange('pd2', !formValues.pd2)}
                >
                  Cerebral palsy
                </Checkbox>
                <Checkbox
                  isChecked={formValues.pd3}
                  onChange={() => handleChange('pd3', !formValues.pd3)}
                >
                  Muscular Dystrophy
                </Checkbox>
                <Checkbox
                  isChecked={formValues.pd4}
                  onChange={() => handleChange('pd4', !formValues.pd4)}
                >
                  Spina Bifida
                </Checkbox>
                <Checkbox
                  isChecked={formValues.pd5}
                  onChange={() => handleChange('pd5', !formValues.pd5)}
                >
                  Polio
                </Checkbox>
                <Checkbox
                  isChecked={formValues.pd6}
                  onChange={() => handleChange('pd6', !formValues.pd6)}
                >
                  Dwarfism
                </Checkbox>
                <Checkbox
                  isChecked={formValues.pd7}
                  onChange={() => handleChange('pd7', !formValues.pd7)}
                >
                  Paralysis
                </Checkbox>
                <Checkbox
                  isChecked={formValues.pd8}
                  onChange={() => handleChange('pd8', !formValues.pd8)}
                >
                  Bone Deficiency
                </Checkbox>
                <Checkbox
                  isChecked={formValues.pd9}
                  onChange={() => handleChange('pd9', !formValues.pd9)}
                >
                  Muteness (by any reason)
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Visual Impairment
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox
                  isChecked={formValues.vi1}
                  onChange={() => handleChange('vi1', !formValues.vi1)}
                >
                  Blindness
                </Checkbox>
                <Checkbox
                  isChecked={formValues.vi2}
                  onChange={() => handleChange('vi2', !formValues.vi2)}
                >
                  Low Vision
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Hearing Impairment
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox
                  isChecked={formValues.hi1}
                  onChange={() => handleChange('hi1', !formValues.hi1)}
                >
                  Deaf
                </Checkbox>
                <Checkbox
                  isChecked={formValues.hi2}
                  onChange={() => handleChange('hi2', !formValues.hi2)}
                >
                  Hard Of Hearing
                </Checkbox>
                <Checkbox
                  isChecked={formValues.hi3}
                  onChange={() => handleChange('hi3', !formValues.hi3)}
                >
                  Late Deafened
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Neuro - developmental Disorder
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox
                  isChecked={formValues.nd1}
                  onChange={() => handleChange('nd1', !formValues.nd1)}
                >
                  Intellectual Disability
                </Checkbox>
                <Checkbox
                  isChecked={formValues.nd2}
                  onChange={() => handleChange('nd2', !formValues.nd2)}
                >
                  Autism Spectrum Disorder
                </Checkbox>
                <Checkbox
                  isChecked={formValues.nd3}
                  onChange={() => handleChange('nd3', !formValues.nd3)}
                >
                  Attention Deficit Hyper Activity Disorder
                </Checkbox>
                <Checkbox
                  isChecked={formValues.nd4}
                  onChange={() => handleChange('nd4', !formValues.nd4)}
                >
                  Specific Learning Disorder
                </Checkbox>
                <Checkbox
                  isChecked={formValues.nd5}
                  onChange={() => handleChange('nd5', !formValues.nd5)}
                >
                  Communication Disorder
                </Checkbox>
                <Checkbox
                  isChecked={formValues.nd6}
                  onChange={() => handleChange('nd6', !formValues.nd6)}
                >
                  Down Syndrome
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Disabilities Caused Due To Chronic Neurological Conditions
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox
                  isChecked={formValues.cn1}
                  onChange={() => handleChange('cn1', !formValues.cn1)}
                >
                  Multiple Sclerosis
                </Checkbox>
                <Checkbox
                  isChecked={formValues.cn2}
                  onChange={() => handleChange('cn2', !formValues.cn2)}
                >
                  Parkinson’s Disease
                </Checkbox>
                <Checkbox
                  isChecked={formValues.cn3}
                  onChange={() => handleChange('cn3', !formValues.cn3)}
                >
                  Dementia
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Multiple Disabilities
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox
                  isChecked={formValues.md}
                  onChange={() => handleChange('md', !formValues.md)}
                >
                  Multiple Disabilities
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  CAUSE OF DISABILITY
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox
                  isChecked={formValues.cd1}
                  onChange={() => handleChange('cd1', !formValues.cd1)}
                >
                  By Birth
                </Checkbox>
                <Checkbox
                  isChecked={formValues.cd2}
                  onChange={() => handleChange('cd2', !formValues.cd2)}
                >
                  Disease
                </Checkbox>
                <Checkbox
                  isChecked={formValues.cd3}
                  onChange={() => handleChange('cd3', !formValues.cd3)}
                >
                  Accident
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </MyBox>
      <Flex my={4} justify="space-between">
        <Flex gap={2}>
          <Button
            colorScheme="green"
            loadingText="Updating..."
            isLoading={btnLoading}
            size={{ base: 'sm', md: 'md' }}
            onClick={() => handleSave(formValues)}
          >
            Save
          </Button>
          <Button
            colorScheme="green"
            size={{ base: 'sm', md: 'md' }}
            onClick={() => handlePdfDownload(formValues)}
            // isDisabled={true}
          >
            Print Certificate
          </Button>
          <Button
            colorScheme="green"
            size={{ base: 'sm', md: 'md' }}
            onClick={() => handlePdfDownloadTransparent(formValues)}
            // isDisabled={true}
          >
            Print On Certificate
          </Button>
        </Flex>
        <Box>
          <Button
            colorScheme="green"
            size={{ base: 'sm', md: 'md' }}
            leftIcon={<MdOutlineQrCode2 />}
            onClick={() => handlePdfQRAdd(formValues)}
            // isDisabled={true}
          >
            Add QR
          </Button>
        </Box>
      </Flex>
      <Spacer h="60vh" display={{ base: 'block', lg: 'none' }} />
    </Box>
  );
};

export default Edit;
