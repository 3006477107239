import React, { useState } from 'react';
import { Box, Stack, Text, Input, Button, useToast } from '@chakra-ui/react';
import MyBox from '../../../component/Layout/MyBox';
import { addCity } from '../../../api/cityApi';

const Add = ({ onClose, onSave }) => {
  const [formValues, setFormValues] = useState({
    name: '',
  });

  const handleChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const toast = useToast();
  const [btnLoading, setBtnLoading] = useState(false);

  const handleAddCity = async () => {
    try {
      const newCityId = await addCity(formValues);

      toast({
        title: 'City added.',
        description: 'The City has been added successfully.',
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
      setBtnLoading(true);
      onSave({ id: newCityId, name: formValues.name }, 'add').finally(() => {
        setBtnLoading(false);
        onClose(); // Close the drawer after save
      });
    } catch (error) {
      console.error('Error adding City:', error);
      toast({
        title: 'Error adding City.',
        description:
          error.response.data.message ||
          'There was an error adding the City. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(false);
    }
  };

  return (
    <Stack>
      <MyBox>
        <Text fontWeight="semibold">Name</Text>
        <Input
          name="name"
          value={formValues.name}
          onChange={e => handleChange('name', e.target.value)}
        />
      </MyBox>
      <Button colorScheme="green" onClick={handleAddCity}>
        Add
      </Button>
    </Stack>
  );
};

export default Add;
