import React from 'react';
import Page from '../../component/Layout/Page';
import { Heading } from '@chakra-ui/react';
import Layout from './components/Layout';

const Settings = ({ sideBarWidth }) => {
  return (
    <Page sideBarWidth={sideBarWidth}>
      <Heading>Settings</Heading>
      <Layout />
    </Page>
  );
};

export default Settings;
