import React, { useState, useEffect } from 'react';
import { ChakraProvider, Text, theme } from '@chakra-ui/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SideBar from './navigation/SidebarHeader';
import SignIn from './pages/signIn/SignIn';
import Dashboard from './pages/dashboard/Dashboard';
import NotFound from './component/Layout/NotFound';
import Certificates from './pages/certificates/Certificates';
import Districts from './pages/districts/Districts';
import City from './pages/city/City';
import Users from './pages/users/Users';
import Settings from './pages/settings/Settings';
import UserPreview from './pages/userPreview/UserPreview';
import Footer from './navigation/footer';
import OldRecords from './pages/oldRecords/OldRecords';
import FIlesList from './pages/oldRecords/components/FIlesList';
import Loader from './component/Loader/Loader';

function App() {
  console.log('VERSION_0.0.6')
  const [isLoggedIn, setIsLoggedIn] = useState(null); // Use null as initial state
  const [userRole, setUserRole] = useState(null);
  const [sideBarWidth, setSideBarWidth] = useState('large');
  const [loading, setLoading] = useState(true);
  const handleSidebarWidth = () => {
    setSideBarWidth(prevWidth => (prevWidth === 'small' ? 'large' : 'small'));
  };

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    const role = localStorage.getItem('role'); // Get the role from local storage
    setIsLoggedIn(loggedInStatus);
    setUserRole(role);
    setLoading(false);
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <ChakraProvider theme={theme}>
      {isLoggedIn ? (
        <>
          <SideBar
            sideBarWidth={sideBarWidth}
            handleSidebarWidth={handleSidebarWidth}
          />
          <Routes>
            <Route path="/" element={<Navigate to={'/certificates'} />} />
            {userRole === 'admin' && (
              <>
                <Route
                  path="/dashboard"
                  element={<Dashboard sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/districts"
                  element={<Districts sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/city"
                  element={<City sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/users"
                  element={<Users sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/certificates"
                  element={<Certificates sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/settings"
                  element={<Settings sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/old-records"
                  element={<OldRecords sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/old-records/:year"
                  element={<FIlesList sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/certificate-details/:id"
                  element={
                    <UserPreview sideBarWidth={sideBarWidth} IsAdmin={true} />
                  }
                />
              </>
            )}
            {userRole === 'employee' && (
              <>
                <Route
                  path="/dashboard"
                  element={<Dashboard sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/certificates"
                  element={<Certificates sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/old-records"
                  element={<OldRecords sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/old-records/:year"
                  element={<FIlesList sideBarWidth={sideBarWidth} />}
                />
                <Route
                  path="/certificate-details/:id"
                  element={
                    <UserPreview sideBarWidth={sideBarWidth} IsAdmin={true} />
                  }
                />
              </>
            )}

            <Route
              path="/*"
              element={<NotFound sideBarWidth={sideBarWidth} />}
            />
          </Routes>
          <Footer />
        </>
      ) : (
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/*" element={<SignIn />} />
          <Route path="/certificate-details/:id" element={<UserPreview />} />
        </Routes>
      )}
    </ChakraProvider>
  );
}

export default App;
