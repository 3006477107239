import React from "react";
import "./loader.css";
import { AbsoluteCenter, Center } from "@chakra-ui/react";
const Loader = () => {
  return (
    <AbsoluteCenter >
      <div class="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </AbsoluteCenter>
  );
};

export default Loader;
