import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Text,
  Input,
  VStack,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react';
import MyBox from '../../../component/Layout/MyBox';
import { fetchCities } from '../../../api/cityApi';
import { updateDistrict } from '../../../api/districtsApi';
import { useBgColor, useBgColorChild } from '../../../utils/colors';

const Edit = ({ selectedItem, onClose, onSave }) => {
  const toast = useToast();
  const [searchTermItems, setSearchTermItems] = useState('');
  const [filteredCities, setFilteredCities] = useState([]); // Initialize as an empty array
  const [searchTerm, setSearchTerm] = useState(selectedItem.city_name);
  const [formValues, setFormValues] = useState({
    name: selectedItem.name,
    city_id: selectedItem.city_id,
  });
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCities(1,20,searchTermItems);

        if (response?.data) {
          setFilteredCities(response.data);
        } else {
          setFilteredCities([]); // Clear cities if no data is returned
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
        setFilteredCities([]); // Handle error
      }
    };
    fetchData();
  }, [searchTermItems]);

  const searchTermResults = filteredCities.filter(item =>
    item.name.toLocaleLowerCase().includes(searchTermItems.toLocaleLowerCase())
  );


  const handleChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = async () => {
    if (!formValues.name || !formValues.city_id) {
      toast({
        title: 'Validation error.',
        description: 'Please fill out the name and select a city.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    try {
      const updatedData = {
        name: formValues.name,
        city_id: formValues.city_id,
      };
      await updateDistrict(selectedItem.id, updatedData);
      toast({
        title: 'District updated.',
        description: 'The district has been updated successfully.',
        status: 'success',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(true);
      onSave(
        { ...updatedData, id: selectedItem.id, city_name: searchTerm },
        'edit'
      ).finally(() => {
        setBtnLoading(false);
        onClose();
      });
    } catch (error) {
      console.error('Error updating district:', error);
      toast({
        title: 'Error updating district.',
        description:
          error.response?.data?.message ||
          'There was an error updating the district. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(false);
    }
  };

  return (
    <Stack>
      <MyBox>
        <Text fontWeight="semibold">Name</Text>
        <Input
          name="name"
          value={formValues.name}
          onChange={e => handleChange('name', e.target.value)}
        />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">City</Text>
        <Box>
          <Input
            name="city"
            placeholder="Search city"
            value={searchTerm}
            onChange={e => {
              setSearchTerm(null);
              setSearchTermItems(e.target.value);
            }}
          />
          {searchTermItems && searchTermResults.length > 0 && (
            <VStack
              align="start"
              spacing={2}
              // borderWidth="1px"
              // borderColor="gray.200"
              borderRadius="md"
              bg={useBgColor}
              p={2}
              mt={1}
            >
              {searchTermResults.map((result, index) => (
                <Box
                  key={index}
                  p={2}
                  bg={useBgColorChild}
                  // borderWidth="1px"
                  // borderColor="gray.200"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => {
                    setSearchTerm(result.name);
                    setSearchTermItems('');
                    handleChange('city_id', result.id);
                  }}
                >
                  {result.name}
                </Box>
              ))}
            </VStack>
          )}
          {searchTermItems && searchTermResults.length === 0 && (
            <Text>No cities found.</Text>
          )}
        </Box>
      </MyBox>
      <Flex gap={2}>
        <Button colorScheme="red" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          colorScheme="green"
          onClick={handleSave}
          isLoading={btnLoading}
          loadingText="Saving..."
        >
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default Edit;
