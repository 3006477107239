import React, { useRef, useState } from 'react';
import MyBox from '../../../component/Layout/MyBox';
import {
  Box,
  Button,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { uploadImage } from '../../../api/settingApi';
import { BASE_URL } from '../../../api/constants';

const Layout = () => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
const [imgLink,setImgLink]=useState(`${BASE_URL}/settings/image/1`)


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const toast = useToast();
  const handleFileChange = async event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);

      // Create a preview URL for the file
      const previewUrl = URL.createObjectURL(selectedFile);
      setFilePreview(previewUrl);

      // Upload the selected image
      const formData = new FormData();
      formData.append('image', selectedFile);
      try {
        await uploadImage(formData);
        toast({
          title: 'image updated.',
          description: 'The image has been updated successfully.',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error updating image',
          description: error.response.data.message || 'Error uploading image',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        // Handle error, show toast message, etc.
      }
    }
  };

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2 }} my={4} gap={4}>
        <Button
          p={10}
          colorScheme="green"
          borderRadius="lg"
          shadow="xl"
          as={Link}
          to="/districts"
          rightIcon={<FaArrowRight />}
        >
          <Text fontWeight="semibold" fontSize={{ base: 'lg', md: 'lg' }}>
            Manage Districts
          </Text>
        </Button>
        <Button
          p={10}
          colorScheme="green"
          borderRadius="lg"
          shadow="xl"
          as={Link}
          to="/city"
          rightIcon={<FaArrowRight />}
        >
          <Text fontWeight="semibold" fontSize={{ base: 'lg', md: 'lg' }}>
            Manage Cities
          </Text>
        </Button>
        {/* <MyBox>
          <Stack align="center">
            {file ? (
              <>
                {file.type.startsWith('image/') ? (
                  <Image src={filePreview} alt="File Preview" maxH="150px" />
                ) : (
                  <a
                    href={filePreview}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Preview PDF
                  </a>
                )}
              </>
            ) : (
              <Image
                src={imgLink}
                alt="File Preview"
                maxH="150px"
              />
            )}
            <Button colorScheme="green" onClick={handleButtonClick}>
              Upload Certificate Background Image
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleFileChange}
            />
          </Stack>
        </MyBox> */}
      </SimpleGrid>
    </Box>
  );
};

export default Layout;
