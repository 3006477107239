import { Box, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Folder = ({ name, files }) => {
  const textColor = useColorModeValue('white', 'black');
  return (
    <Box
      borderRadius="lg"
      shadow="lg"
      _hover={{
        transform: 'scale(1.04)',
        transition: 'transform 0.1s ease-in-out',
        cursor: 'pointer',
      }}
      w="10rem"
      h="8rem"
      bg="green.200"
      as={Link}
      to={`/old-records/${name}`} // Pass the year in the URL
    >
      <Stack>
        <Flex
          bg="green.400"
          px={2}
          py={1}
          align="center"
          justify="space-between"
          fontWeight="bold"
          color={textColor}
        >
          <Text>Year</Text>
          <Text>{name}</Text>
        </Flex>
        <Flex px={2} justify="space-between" fontSize="sm">
          {/* <Text>Total Records:</Text> */}
          {/* <Text textAlign="end">{files}</Text> */}
        </Flex>
      </Stack>
    </Box>
  );
};

const Layout = () => {
  const years = [
    {
      index: 0,
      name: '2021',
      files: 183,
    },
    {
      index: 1,
      name: '2022',
      files: 412,
    },
    {
      index: 2,
      name: 'C2024',
      files: 2,
    },
    {
      index: 3,
      name: 'D2024',
      files: 255,
    },

    {
      index: 4,
      name: '2023',
      files: 494,
    },
  ];
  return (
    <Box>
      <Flex gap={2}>
        {years.map((item, index) => (
          <Folder name={item.name} files={item.files} />
        ))}
      </Flex>
    </Box>
  );
};

export default Layout;
