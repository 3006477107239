import { BASE_URL, getTokenFromLocalStorage } from "./constants";
import axios from 'axios';

export const loginUser = async (email, password) => {
    try {
        const response = await axios.post(`${BASE_URL}/users/login`, {
            email,
            password,
        });
        return response.data;
    } catch (error) {
        throw error.response.data.message;
    }
};

export const getUsers = async (role, email, name, page, limit) => {
    try {
        const countResponse = await axios.get(`${BASE_URL}/users/totalItems`, {
            params: {
              role,
              email,
              name,
            },
            headers: {
              token: getTokenFromLocalStorage(),
            },
          });
          
        const totalItems = countResponse.data.totalItems;
        const response = await axios.get(`${BASE_URL}/users`, {
            params: { role, email, name, page, limit },
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return { data: response.data, totalPages: Math.ceil(totalItems / limit), };
    } catch (error) {
        console.error('Error fetching users:', error);
        return { data: [], totalPages: 0 };
    }
};

export const createUser = async (userData) => {
    try {
        const response = await axios.post(`${BASE_URL}/users/create`, userData, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

export const deleteUser = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/users/${id}`, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
};


export const updateUser = async (userId, userData) => {
    try {
        const response = await axios.put(`${BASE_URL}/users/${userId}`, userData, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data; // Assuming the updated user data is returned by the API
    } catch (error) {
        throw error; // Throw error to handle it in the component
    }
};

export const getUserById = async (userId) => {
    try {
        const response = await axios.get(`${BASE_URL}/users/${userId}`, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data; // Assuming the user data is returned by the API
    } catch (error) {
        throw error; // Throw error to handle it in the component
    }
};