import React from 'react';
import Page from '../../component/Layout/Page';
import { Flex, Heading, IconButton } from '@chakra-ui/react';
import Layout from './components/Layout';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

const City = ({ sideBarWidth }) => {
  return (
    <Page sideBarWidth={sideBarWidth}>
      <Flex align="center" gap={1}>
        <IconButton
          icon={<IoMdArrowBack />}
          variant="outline"
          colorScheme="green"
          size="sm"
          as={Link}
          to={-1}
        />
        <Heading>City</Heading>
      </Flex>
      <Layout />
    </Page>
  );
};

export default City;
