import {
  Button,
  Flex,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import MyBox from '../../../component/Layout/MyBox';
import { createUser } from '../../../api/userApi';

const Add = ({ onSave, onClose }) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    fullname: '',
    email: '',
    password: '',
    contact_number: '',
    role: 'admin',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const toast = useToast();
  const handleSubmit = async () => {
    try {
      const { id } = await createUser(formValues); // Assuming createUser returns an object with an 'id' property
      const newUser = { ...formValues, id };
      toast({
        title: 'User added.',
        description: 'The user has been added successfully.',
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
      setBtnLoading(true);
      onSave(newUser, 'add').finally(() => {
        setBtnLoading(false);
        onClose(); // Close the drawer after save
      });
    } catch (error) {
      console.error('Error adding user:', error);
      toast({
        title: 'Error adding user.',
        description:
          error.response.data.message ||
          'There was an error adding the user. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(false);
    }
  };

  return (
    <Stack mb={10}>
      <MyBox>
        <Text fontWeight="semibold">Name</Text>
        <Input
          name="fullname"
          type="text"
          value={formValues.fullname}
          onChange={handleChange}
        />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Email</Text>
        <Input name="email" value={formValues.email} onChange={handleChange} />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Password</Text>
        <Input
          name="password"
          value={formValues.password}
          type="password"
          onChange={handleChange}
        />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Contact</Text>
        <Input
          name="contact_number"
          value={formValues.contact_number}
          onChange={handleChange}
          type="tel"
        />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Role</Text>
        <Select name="role" value={formValues.role} onChange={handleChange}>
          <option value="admin">Admin</option>
          <option value="employee">Employee</option>
        </Select>
      </MyBox>

      <Flex gap={2}>
        <Button colorScheme="red" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          colorScheme="green"
          isLoading={btnLoading}
          loadingText="Adding..."
          onClick={handleSubmit}
        >
          Add
        </Button>
      </Flex>
    </Stack>
  );
};

export default Add;
