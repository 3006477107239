// UserPreview.jsx

import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, Img } from '@chakra-ui/react';
import govLogo from '../../images/logo/sindhLogo.png';
import depdLogo from '../../images/logo/depdLogo.png';
import Loader from '../../component/Loader/Loader';
import { useParams } from 'react-router-dom';
import { fetchImageOrPdf } from '../../api/certificatesApi';
import { decryptId } from '../../utils/funtions';
import Page from '../../component/Layout/Page';

const UserPreview = ({ sideBarWidth, IsAdmin }) => {
  const { id } = useParams();
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchFile = async () => {
      setIsLoading(true);
      try {
        const decrypedID = decryptId(id);
        const data = await fetchImageOrPdf(decrypedID);
        setFileUrl(data.data);
        setFileType(data.type);
      } catch (error) {
        console.error('Error fetching file:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFile();
  }, []);

  const renderFile = () => {
    if (!fileUrl) {
      return;
      <Box
        width="100%"
        height="100vh" // Set height to half of the viewport height
        display="flex"
        justifyContent="center"
      >
        <p>Certificate not found or image not available</p>;
      </Box>;
    }

    if (fileType.startsWith('image')) {
      return (
        <Box
          width="100%"
          height="100vh" // Set height to half of the viewport height
          display="flex"
          justifyContent="center"
        >
          <img
            src={`data:${fileType};base64,${fileUrl}`}
            alt="Image Preview"
            style={{ maxWidth: '100%', maxHeight: '500px' }}
          />
        </Box>
      );
    } else if (fileType === 'application/pdf') {
      return (
        <Box
          width="100%"
          height="100vh" // Set height to half of the viewport height
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <iframe
            title="PDF Preview"
            src={`data:${fileType};base64,${fileUrl}`}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
          />
        </Box>
      );
    } else {
      return <p>Unsupported file type</p>;
    }
  };
  if (isLoading) {
    return (
      <Box
        width="100vw" // Full width of the viewport
        height="100vh" // Full height of the viewport
        display="flex"
        justifyContent="center" // Center horizontally
        alignItems="center" // Center vertically
        position="absolute" // Positioning
        top="0"
        left="0"
      >
        <Loader />
      </Box>
    );
  }
  return (
    <>
      {IsAdmin ? (
        <Page sideBarWidth={sideBarWidth}>
          <Box padding={10}>
            <Flex justify="center" align="center" gap={2} mb={10}>
              <Img src={govLogo} h="50px" objectFit="cover" />
              <Heading
                fontSize={{ base: 'lg', md: 'xx-large', lg: 'xxx-large' }}
              >
                Certificate Details
              </Heading>
              <Img src={depdLogo} h="50px" objectFit="cover" />
            </Flex>
            {renderFile()}
          </Box>
        </Page>
      ) : (
        <Box padding={10}>
          <Flex justify="center" align="center" gap={2} mb={10}>
            <Img src={govLogo} h="50px" objectFit="cover" />
            <Heading fontSize={{ base: 'lg', md: 'xx-large', lg: 'xxx-large' }}>
              Certificate Details
            </Heading>
            <Img src={depdLogo} h="50px" objectFit="cover" />
          </Flex>
          {renderFile()}
        </Box>
      )}
    </>
  );
};

export default UserPreview;
