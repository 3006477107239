import React, { useState } from 'react';
// import CryptoJS from "crypto-js";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  Image,
  InputRightElement,
  IconButton,
  InputGroup,
  useToast,
  useColorMode,
} from '@chakra-ui/react';

// import signInImage from '../../images/logo/sindhLogo.png';
import signInImage from '../../images/logo/SPDPA_noBG_light.png';
import logoWhite from '../../images/logo/depdLogo.png';
import logoBlack from '../../images/logo/depdLogo.png';
// import LogoBlack from "../../images/FourSeasonLogoBlack.png";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import GradientBorder from './GradientBorder';
import { loginUser } from '../../api/userApi';
// import { signIn } from "../api/signinAPI";
// import { loginUser } from "../../API/api";

function SignIn() {
  const titleColor = useColorModeValue('black', 'white');
  const textColor = useColorModeValue('black', 'white');
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [btnLoading, setButtonLoading] = useState(false);
  //   const secretKey = "sT#9yX^pQ&$mK!2wF@8zL7vA";
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  //   const toast = useToast();
  //   function handleSubmit(event) {
  //     event.preventDefault();

  //     const credentials = {
  //       email: email,
  //       password: password,
  //     };
  //     if (
  //       email === "AdminAccounts@gmail.com" &&
  //       password === "$tr0ngP@ssw0rd2023!"
  //     ) {
  //       const dataToEncrypt = "AccountsAdmin";
  //       const encryptedData = CryptoJS.AES.encrypt(
  //         dataToEncrypt,
  //         secretKey
  //       ).toString();
  //       localStorage.setItem("encryptedData", encryptedData);

  //       localStorage.setItem("isUserLoggedIn", "true");
  //       window.location.href = "/";
  //     } else {
  //       loginUser(credentials)
  //         .then((data) => {
  //           const { user } = data;
  //           if (user && user.authToken) {
  //             const { authToken } = user;
  //             const secretKey = "sT#9yX^pQ&$mK!2wF@8zL7vA";
  //             const dataToEncryptP = password;
  //             const dataToEncrypt = user.department;
  //             const encryptedData = CryptoJS.AES.encrypt(
  //               dataToEncrypt,
  //               secretKey
  //             ).toString();
  //             const encryptedDataP = CryptoJS.AES.encrypt(
  //               dataToEncryptP,
  //               secretKey
  //             ).toString();
  //             localStorage.setItem("encryptedData", encryptedData);
  //             localStorage.setItem("password", encryptedDataP);
  //             localStorage.setItem("email", email);
  //             localStorage.setItem("token", authToken);
  //             localStorage.setItem("Name", user.name);
  //             localStorage.setItem("isUserLoggedIn", "true");
  //             sessionStorage.setItem("user", JSON.stringify(user));

  //             window.location.href = "/";
  //           } else {
  //             console.error(
  //               "Login failed. No authToken found in the user object."
  //             );
  //           }
  //         })
  //         .catch((error) => {
  //           if (
  //             error.response &&
  //             error.response.data &&
  //             error.response.data.error
  //           ) {
  //             toast({
  //               title: "Error",
  //               description: error.response.data.error,
  //               status: "error",
  //               position: "top-right",
  //               duration: 3000,
  //               isClosable: true,
  //             });
  //           }

  //           console.error("Login error:", error);
  //         });
  //     }
  //   }
  const { colorMode } = useColorMode();
  const logo = colorMode === 'light' ? logoBlack : logoWhite;
  const toast = useToast();
  const handleSubmit = async event => {
    event.preventDefault();

    if (email === '' || password === '') {
      toast({
        title: 'Please Enter Email and Password',
        status: 'info',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setButtonLoading(true);
      const response = await loginUser(email, password);

      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('role', response.role);
        localStorage.setItem('email', email);
        localStorage.setItem('id', response.id);
        localStorage.setItem('fullname',response.fullname)
        window.location.href = '/';
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error || 'Login failed',
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setButtonLoading(false);
    }
  };
  const handleFormSubmit = event => {
    event.preventDefault();
    // Trigger the sign-in button click
    if (!btnLoading) {
      // Only trigger the click if the button is not in a loading state
      document.getElementById('sign-in-button').click();
    }
  };

  return (
    <Flex position="relative" bg={bgColor}>
      <Flex
        minH="max-content"
        h={{ base: '120vh', lg: 'fit-content' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        pt={{ sm: '100px', md: '0px' }}
        flexDirection="column"
        me={{ base: 'auto', lg: '50px', xl: 'auto' }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          mx={{ base: 'auto', lg: 'unset' }}
          ms={{ base: 'auto', lg: 'auto' }}
          w={{ base: '100%', md: '50%', lg: '450px' }}
          px="50px"
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            mt={{ base: '25px', md: '25px', lg: '25px', xl: '25px' }}
            mb={{ base: '100px', md: '100px', lg: '100px', xl: '100px' }}
          >
            <form
            // onSubmit={handleFormSubmit}
            >
              <Flex justify="space-evenly">
                <Image mt={20} mb={15} src={signInImage} boxSize="90px" />
                <Image mt={20} mb={15} src={logo} boxSize="90px" />
              </Flex>
              <Heading color={titleColor} fontSize="32px" mb="10px">
                Nice to see you!
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                color={textColor}
                fontWeight="bold"
                fontSize="14px"
              >
                Enter your email and password to sign in
              </Text>
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="normal"
                  color={textColor}
                >
                  Email
                </FormLabel>
                <GradientBorder
                  mb="24px"
                  w={{ base: '100%', lg: 'fit-content' }}
                  borderRadius="20px"
                >
                  <Input
                    color={textColor}
                    bg={bgColor}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: '100%', md: '346px' }}
                    maxW="100%"
                    h="46px"
                    placeholder="Your email address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </GradientBorder>
              </FormControl>
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="normal"
                  color={textColor}
                >
                  Password
                </FormLabel>
                <GradientBorder
                  mb="24px"
                  w={{ base: '100%', lg: 'fit-content' }}
                  borderRadius="20px"
                >
                  <InputGroup>
                    <Input
                      color={textColor}
                      bg={bgColor}
                      border="transparent"
                      borderRadius="20px"
                      fontSize="sm"
                      size="lg"
                      w={{ base: '100%', md: '346px' }}
                      maxW="100%"
                      h="46px"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Your password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <InputRightElement width="4.5rem">
                      <IconButton
                        size="md"
                        bg={bgColor}
                        onClick={handleTogglePassword}
                        icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                      />
                    </InputRightElement>
                  </InputGroup>
                </GradientBorder>
              </FormControl>
              <Button
                id="sign-in-button"
                variant="solid"
                colorScheme="green"
                fontSize="md"
                type="submit"
                w="100%"
                maxW="350px"
                h="45"
                mb="20px"
                mt="20px"
                onClick={handleSubmit}
                isLoading={btnLoading}
              >
                SIGN IN
              </Button>
            </form>
            <Text
              textAlign="center"
              fontWeight="500"
              px={4}
              display={{ base: 'block', lg: 'none' }}
            >
              DESIGNED & DEVELOPED BY{' '}
              <a
                href="https://www.cognisoftlabs.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#9d6ac2',
                }}
              >
                COGNISOFT LABS
              </a>
            </Text>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', lg: 'block' }}
          overflowX="hidden"
          h="100%"
          maxW={{ md: '50vw', lg: '50vw' }}
          minH="100vh"
          w="960px"
          position="absolute"
          left="0px"
        >
          <Box
            bgImage={signInImage}
            // bgColor="green.800"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {/* <Text
              textAlign="center"
              color="black"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)"
            >
              DEPD
            </Text> */}
            <Text
              mt={10}
              textAlign="center"
              color="white"
              bg="purple.400"
              letterSpacing="8px"
              fontSize="14px"
              fontWeight="500"
              mb={20}
              px={4}
              shadow="lg"
              borderRadius="md"
              position="fixed"
              bottom={5}
            >
              DESIGNED & DEVELOPED BY{' '}
              <a
                href="https://www.cognisoftlabs.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                COGNISOFT LABS
              </a>
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
