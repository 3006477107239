import { Box } from '@chakra-ui/react';
import React from 'react';
import { useBgColorChild } from '../../utils/colors';

const MyBox = ({ children, ...rest }) => {
  return (
    <Box bg={useBgColorChild} px={2} py={4} borderRadius="lg" {...rest}>
      {children}
    </Box>
  );
};

export default MyBox;
