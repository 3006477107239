import React from 'react';
import Page from '../../component/Layout/Page';
import { Heading } from '@chakra-ui/react';
import UserList from './components/UserList';

const Users = ({ sideBarWidth }) => {
  return (
    <Page sideBarWidth={sideBarWidth}>
      <Heading>Users</Heading>
      <UserList />
    </Page>
  );
};

export default Users;
