import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useBgColorChild } from '../../../utils/colors';
import MyBox from '../../../component/Layout/MyBox';

const Show = ({ selectedItem }) => {
  return (
    <Box >
      <Stack>
        <MyBox>
          <Text fontWeight="semibold">Name</Text>
          <Text fontWeight="semibold">{selectedItem.fullname}</Text>
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Email</Text>
          <Text fontWeight="semibold">{selectedItem.email}</Text>
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Contact</Text>
          <Text fontWeight="semibold">{selectedItem.contact_number}</Text>
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Role</Text>
          <Text fontWeight="semibold">{selectedItem.role}</Text>
        </MyBox>
      </Stack>
    </Box>
  );
};

export default Show;
