import {
  Button,
  Flex,
  Input,
  Select,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import MyBox from '../../../component/Layout/MyBox';
import { updateUser } from '../../../api/userApi';

const Edit = ({ selectedItem, onSave, onClose }) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    fullname: selectedItem.fullname,
    email: selectedItem.email,
    password: '',
    contact_number: selectedItem.contact_number,
    role: selectedItem.role,
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const toast = useToast();
  const handleUpdate = async () => {
    try {
      setBtnLoading(true);
      // Send password only if it's not an empty string
      const userData = { ...formValues };
      if (formValues.password === '') {
        delete userData.password;
      }
      await updateUser(selectedItem.id, userData);
      toast({
        title: 'User updated.',
        description: 'The user information has been updated successfully.',
        status: 'success',
        duration: 5000,
        position:'top-right',
        isClosable: true,
      });
      setBtnLoading(true);
      onSave({ ...userData, id: selectedItem.id }, 'edit').finally(() => {
        setBtnLoading(false);
        onClose(); // Close the drawer after save
      });
    } catch (error) {
      console.error('Error updating user:', error);
      toast({
        title: error.response.data.message || 'Error updating user.',
        description:
          'There was an error updating the user information. Please try again.',
        status: 'error',
        duration: 5000,
        position:'top-right',
        isClosable: true,
      });
      setBtnLoading(false);

    }
  };
  

  return (
    <Stack>
      <MyBox>
        <Text fontWeight="semibold">Name</Text>
        <Input
          name="fullname"
          type="text"
          value={formValues.fullname}
          onChange={handleChange}
        />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Email</Text>
        <Input name="email" value={formValues.email} onChange={handleChange} />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Password</Text>
        <Input
          name="password"
          value={formValues.password}
          type="password"
          onChange={handleChange}
        />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Contact</Text>
        <Input
          name="contact_number"
          value={formValues.contact_number}
          onChange={handleChange}
          type="tel"
        />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">Role</Text>
        <Select name="role" value={formValues.role} onChange={handleChange}>
          <option value="admin">Admin</option>
          <option value="employee">Employee</option>
        </Select>{' '}
      </MyBox>

      <Flex gap={2}>
        <Button colorScheme="red" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          colorScheme="green"
          isLoading={btnLoading}
          loadingText="Updating..."
          //    onClick={onSave()}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Flex>
    </Stack>
  );
};

export default Edit;
