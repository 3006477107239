import React, { useState } from 'react';
import {
  Box,
  Stack,
  Text,
  Input,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react';
import MyBox from '../../../component/Layout/MyBox';
import { updateCity } from '../../../api/cityApi';

const Edit = ({ selectedItem, onClose, onSave }) => {
  const [formValues, setFormValues] = useState({
    name: selectedItem.name,
  });

  const handleChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const toast = useToast();
  const handleSave = async () => {
    try {
      await updateCity(selectedItem.id, formValues);
      toast({
        title: 'City updated.',
        description: 'The City has been updating successfully.',
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
      setBtnLoading(true);
      onSave({ id: selectedItem.id, name: formValues.name }, 'edit').finally(
        () => {
          setBtnLoading(false);
          onClose(); // Close the drawer after save
        }
      );
    } catch (error) {
      toast({
        title: 'Error updating City.',
        description:
          error.response.data.message ||
          'There was an error updating the City. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(false);
    }
  };

  return (
    <Stack>
      <MyBox>
        <Text fontWeight="semibold">Name</Text>
        <Input
          name="name"
          value={formValues.name}
          onChange={e => handleChange('name', e.target.value)}
        />
      </MyBox>
      <Flex gap={2}>
        <Button colorScheme="red" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button colorScheme="green" onClick={handleSave}>
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default Edit;
