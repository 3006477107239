import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Text,
  Input,
  VStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import MyBox from '../../../component/Layout/MyBox';
import { fetchCities } from '../../../api/cityApi';
import { addDistrict } from '../../../api/districtsApi';
import { useBgColor, useBgColorChild } from '../../../utils/colors';

const Add = ({ onClose, onSave }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermItems, setSearchTermItems] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    city_id: '',
    city_name: ' ',
  });
  const [filteredCities, setFilteredCities] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchCities(1,20,searchTermItems);

      if (response?.data) {
        setFilteredCities(response.data);
      } else {
        setFilteredCities([]); // Clear cities if no data is returned
      }
    };
    fetchData();
  }, [searchTermItems]);

  const handleChange = (name, value) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formValues.name || !formValues.city_id) {
      // Validate that both name and city are selected
      toast({
        title: 'Validation error.',
        description: 'Please fill out the name and select a city.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    try {
      const city_name = formValues.city_name;
      delete formValues.city_name;
      const { id } = await addDistrict(formValues);
      const newDistrict = { ...formValues, id, city_name };

      toast({
        title: 'District added.',
        description: 'The district has been added successfully.',
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });
      setBtnLoading(true);
      onSave(newDistrict, 'add').finally(() => {
        setBtnLoading(false);
        onClose(); // Close the drawer after save
      });
    } catch (error) {
      console.error('Error adding district:', error);
      toast({
        title: 'Error adding district.',
        description:
          error.response?.data?.message ||
          'There was an error adding the district. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(false);
    }
  };

  return (
    <Stack>
      <MyBox>
        <Text fontWeight="semibold">Name</Text>
        <Input
          name="name"
          value={formValues.name}
          onChange={e => handleChange('name', e.target.value)}
        />
      </MyBox>
      <MyBox>
        <Text fontWeight="semibold">City</Text>
        <Box>
          <Input
            name="city"
            placeholder="Search city"
            value={searchTerm}
            onChange={e => {
              setSearchTerm(null);
              setSearchTermItems(e.target.value);
            }}
          />
          {searchTermItems && filteredCities.length > 0 && (
            <VStack
              align="start"
              spacing={2}
              // borderWidth="1px"
              // borderColor="gray.200"
              bg={useBgColor}
              borderRadius="md"
              mt={1}
              p={2}
            >
              {filteredCities.map((result, index) => (
                <Box
                  key={index}
                  p={2}
                  bg={useBgColorChild}
                  // borderWidth="1px"
                  // borderColor="gray.200"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => {
                    setSearchTerm(result.name);
                    setSearchTermItems('');
                    handleChange('city_id', result.id);
                    handleChange('city_name', result.name);
                  }}
                >
                  {result.name}
                </Box>
              ))}
            </VStack>
          )}
          {searchTermItems && filteredCities.length === 0 && (
            <Text>No cities found.</Text>
          )}
        </Box>
      </MyBox>
      <Button
        colorScheme="green"
        onClick={handleSubmit}
        isLoading={btnLoading}
        loadingText="Adding..."
      >
        Add
      </Button>
    </Stack>
  );
};

export default Add;
