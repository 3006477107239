import React from 'react';
import Page from '../../component/Layout/Page';
import { Heading } from '@chakra-ui/react';

const Dashboard = ({ sideBarWidth }) => {
  return <Page sideBarWidth={sideBarWidth}>
    <Heading>Dasboard</Heading>
  </Page>;
};

export default Dashboard;
