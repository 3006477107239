import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  Input,
  VStack,
  Button,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  Flex,
  Spacer,
  useToast,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import MyBox from '../../../component/Layout/MyBox';
import { cities, districts } from '../../../utils/dummyData';
import { useBgColorChild } from '../../../utils/colors';
import QR from 'qrcode-base64';
import { printPdf, returnPdf } from '../../../utils/certificateGen';
import { printPdfTransparent } from '../../../utils/centificateGenTransparent';
import { createCertificate } from '../../../api/certificatesApi';
import { fetchDistricts } from '../../../api/districtsApi';
import { encryptId } from '../../../utils/funtions';
import { BASE_FRONTEND_URL } from '../../../api/constants';
import { MdOutlineQrCode2 } from 'react-icons/md';
import { printPdfQR } from '../../../utils/addQR';

const Add = ({ onClose, onSave }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const qrCodeRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [districts, setDistricts] = useState([]);
  const [formValues, setFormValues] = useState({
    district: '',
    date_of_issue: '',
    serial_no: '',
    reg_no: '',
    name: '',
    childO: '',
    date_of_birth: '',
    cnic: '',
    education: '',
    domicile: '',
    religion: '',
    address: '',
    contactNo: '',
    email: '',
    pd1: false,
    pd2: false,
    pd3: false,
    pd4: false,
    pd5: false,
    pd6: false,
    pd7: false,
    pd8: false,
    pd9: false,
    vi1: false,
    vi2: false,
    hi1: false,
    hi2: false,
    hi3: false,
    nd1: false,
    nd2: false,
    nd3: false,
    nd4: false,
    nd5: false,
    nd6: false,
    cn1: false,
    cn2: false,
    cn3: false,
    md: false,
    cd1: false,
    cd2: false,
    cd3: false,
    extentOfDisability: '',
    district_id: null,
  });
  let url = '';
  const toast = useToast();

  useEffect(() => {
    const getDistricts = async () => {
      try {
        const data = await fetchDistricts(1, 10, searchTerm);
        setDistricts(data.data);
      } catch (error) {
        console.error('Error fetching districts:', error);
      }
    };
    getDistricts();
  }, [searchTerm]);

  const handleChange = (name, value) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const generateQrCode = url => {
    var imgData = QR.drawImg(url, {
      typeNumber: 5,
      errorCorrectLevel: 'M',
      size: 150,
    });
    return imgData;
  };

  const handleSave = async (data,throwError=false) => {
    try {
      setBtnLoading(true);

      const formData = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      formData.delete('district');

      const response = await createCertificate(formData);

      toast({
        title: 'Certificate added.',
        description: 'The certificate has been added successfully.',
        status: 'success',
        position: 'top-right',
        duration: 5000,
        isClosable: true,
      });

      onSave(
        { ...response.certificate, scanned_certificate_status: 'not uploaded' },
        'add'
      ).finally(() => {
        setBtnLoading(false);
        // onClose();
      });
      const id = response?.certificate?.id;
       url = `${BASE_FRONTEND_URL}/certificate-details/${encryptId(id)}`;
    } catch (error) {
      console.error('Error creating certificate:', error);
      toast({
        title: 'Error adding certificate.',
        description:
          error?.response?.data?.message ||
          'There was an error adding the certificate. Please try again.',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setBtnLoading(false);
      if(throwError){
      throw error;}
    }
  };

  const handlePdfDownload = async data => {
    try {
      await handleSave(formValues,true);
      const qrcode = await generateQrCode(url);
      printPdf(data, qrcode);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handlePdfDownloadTransparent = async data => {
    try {
      await handleSave(formValues,true);
      const qrcode = await generateQrCode(url);
      printPdfTransparent(data, qrcode);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  const handlePdfQRAdd = async data => {
    try {
      await handleSave(formValues,true);
      const qrcode = await generateQrCode(url);
      printPdfQR(qrcode);
      onClose();
    } catch (error) {
      console.error('Error creating certificate:', error);
    }
  };
  return (
    <Box overflowY="scroll">
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={2}>
        <MyBox>
          <FormControl isRequired>
            <FormLabel fontWeight="semibold">District</FormLabel>
            <Box>
              <Input
                name="district"
                placeholder="Search district"
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value);
                }}
              />
              {districts && districts.length > 0 && (
                <VStack
                  align="start"
                  spacing={2}
                  borderWidth="1px"
                  borderColor="gray.200"
                  borderRadius="md"
                  mt={1}
                >
                  {districts.map((result, index) => {
                    if (
                      districts.length === 1 &&
                      result.id === formValues.district_id
                    ) {
                      return null; // Skip rendering if the district has only one item and its ID matches formValues.district_id
                    }
                    return (
                      <Box
                        key={index}
                        p={2}
                        bg={useBgColorChild}
                        borderWidth="1px"
                        borderColor="gray.200"
                        borderRadius="md"
                        cursor="pointer"
                        onClick={() => {
                          setSearchTerm(result.name);
                          handleChange('district_id', result.id);
                          handleChange('district', result.name);
                        }}
                      >
                        {result.name}
                      </Box>
                    );
                  })}
                </VStack>
              )}
            </Box>
          </FormControl>
        </MyBox>
        <MyBox>
          <FormControl isRequired>
            <FormLabel fontWeight="semibold">Registration No.</FormLabel>
            <Input
              name="reg_no"
              value={formValues.reg_no}
              onChange={e => handleChange('reg_no', e.target.value)}
              isRequired
            />
          </FormControl>
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">DATE OF ISSUE</Text>
          <Input
            name="date_of_issue"
            value={formValues.date_of_issue}
            type="date"
            onChange={e => handleChange('date_of_issue', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">SERIAL No</Text>
          <Input
            name="serial_no"
            value={formValues.serial_no}
            onChange={e => handleChange('serial_no', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Name</Text>
          <Input
            name="name"
            value={formValues.name}
            onChange={e => handleChange('name', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">S/O, D/O, W/O</Text>
          <Input
            name="childO"
            value={formValues.childO}
            onChange={e => handleChange('childO', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">DATE OF BIRTH:</Text>
          <Input
            name="date_of_birth"
            value={formValues.date_of_birth}
            type="date"
            onChange={e => handleChange('date_of_birth', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">SCNIC#/SNICOP#:</Text>
          <Input
            name="cnic"
            value={formValues.cnic}
            onChange={e => handleChange('cnic', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">EDUCATIONAL QUALIFICATION:</Text>
          <Input
            name="education"
            value={formValues.education}
            onChange={e => handleChange('education', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">DOMICILE:</Text>
          <Input
            name="domicile"
            value={formValues.domicile}
            onChange={e => handleChange('domicile', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">RELIGION:</Text>
          <Input
            name="religion"
            value={formValues.religion}
            onChange={e => handleChange('religion', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">POSTAL ADDRESS:</Text>
          <Input
            name="address"
            value={formValues.address}
            onChange={e => handleChange('address', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">CONTACT NO.:</Text>
          <Input
            name="contactNo"
            value={formValues.contactNo}
            onChange={e => handleChange('contactNo', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">EMAIL:</Text>
          <Input
            name="email"
            value={formValues.email}
            onChange={e => handleChange('email', e.target.value)}
          />
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Extent of Disability:</Text>
          <Input
            name="extentOfDisability"
            value={formValues.extentOfDisability}
            onChange={e => handleChange('extentOfDisability', e.target.value)}
          />
        </MyBox>
      </SimpleGrid>

      <MyBox my={4}>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Physical Disabilities
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox onChange={() => handleChange('pd1', !formValues.pd1)}>
                  Locomotor Disability
                </Checkbox>
                <Checkbox onChange={() => handleChange('pd2', !formValues.pd2)}>
                  Cerebral palsy
                </Checkbox>
                <Checkbox onChange={() => handleChange('pd3', !formValues.pd3)}>
                  Muscular Dystrophy
                </Checkbox>
                <Checkbox onChange={() => handleChange('pd4', !formValues.pd4)}>
                  Spina Bifida
                </Checkbox>
                <Checkbox onChange={() => handleChange('pd5', !formValues.pd5)}>
                  Polio
                </Checkbox>
                <Checkbox onChange={() => handleChange('pd6', !formValues.pd6)}>
                  Dwarfism
                </Checkbox>
                <Checkbox onChange={() => handleChange('pd7', !formValues.pd7)}>
                  Paralysis
                </Checkbox>
                <Checkbox onChange={() => handleChange('pd8', !formValues.pd8)}>
                  Bone Deficiency
                </Checkbox>
                <Checkbox onChange={() => handleChange('pd9', !formValues.pd9)}>
                  Muteness (by any reason)
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Visual Impairment
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox onChange={() => handleChange('vi1', !formValues.vi1)}>
                  Blindness
                </Checkbox>
                <Checkbox onChange={() => handleChange('vi2', !formValues.vi2)}>
                  Low Vision
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Hearing Impairment
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox onChange={() => handleChange('hi1', !formValues.hi1)}>
                  Deaf
                </Checkbox>
                <Checkbox onChange={() => handleChange('hi2', !formValues.hi2)}>
                  Hard Of Hearing
                </Checkbox>
                <Checkbox onChange={() => handleChange('hi3', !formValues.hi3)}>
                  Late Deafened
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Neuro - developmental Disorder
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox onChange={() => handleChange('nd1', !formValues.nd1)}>
                  Intellectual Disability
                </Checkbox>
                <Checkbox onChange={() => handleChange('nd2', !formValues.nd2)}>
                  Autism Spectrum Disorder
                </Checkbox>
                <Checkbox onChange={() => handleChange('nd3', !formValues.nd3)}>
                  Attention Deficit Hyper Activity Disorder
                </Checkbox>
                <Checkbox onChange={() => handleChange('nd4', !formValues.nd4)}>
                  Specific Learning Disorder
                </Checkbox>
                <Checkbox onChange={() => handleChange('nd5', !formValues.nd5)}>
                  Communication Disorder
                </Checkbox>
                <Checkbox onChange={() => handleChange('nd6', !formValues.nd6)}>
                  Down Syndrome
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Disabilities Caused Due To Chronic Neurological Conditions
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox onChange={() => handleChange('cn1', !formValues.cn1)}>
                  Multiple Sclerosis
                </Checkbox>
                <Checkbox onChange={() => handleChange('cn2', !formValues.cn2)}>
                  Parkinson’s Disease
                </Checkbox>
                <Checkbox onChange={() => handleChange('cn3', !formValues.cn3)}>
                  Dementia
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Multiple Disabilities
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox onChange={() => handleChange('md', !formValues.md)}>
                  Multiple Disabilities
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  CAUSE OF DISABILITY
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex wrap="wrap" gap={2}>
                <Checkbox onChange={() => handleChange('cd1', !formValues.cd1)}>
                  By Birth
                </Checkbox>
                <Checkbox onChange={() => handleChange('cd2', !formValues.cd2)}>
                  Disease
                </Checkbox>
                <Checkbox onChange={() => handleChange('cd3', !formValues.cd3)}>
                  Accident
                </Checkbox>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </MyBox>
      <Flex my={4} justify="space-between">
        <Flex gap={2}>
          <Button
            colorScheme="green"
            loadingText="Adding..."
            isLoading={btnLoading}
            size={{ base: 'sm', md: 'md' }}
            onClick={() => handleSave(formValues)}
          >
            Save
          </Button>
          <Button
            colorScheme="green"
            size={{ base: 'sm', md: 'md' }}
            onClick={() => handlePdfDownload(formValues)}
            // isDisabled={true}
          >
            Print Certificate
          </Button>
          <Button
            colorScheme="green"
            size={{ base: 'sm', md: 'md' }}
            onClick={() => handlePdfDownloadTransparent(formValues)}
            // isDisabled={true}
          >
            Print On Certificate
          </Button>
        </Flex>
        <Box>
          <Button
            colorScheme="green"
            size={{ base: 'sm', md: 'md' }}
            leftIcon={<MdOutlineQrCode2 />}
            onClick={() => handlePdfQRAdd(formValues)}
            // isDisabled={true}
          >
            Add QR
          </Button>
        </Box>
      </Flex>
      <Spacer h="60vh" display={{ base: 'block', lg: 'none' }} />
    </Box>
  );
};

export default Add;
