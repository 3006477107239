import axios from "axios";
import { BASE_URL, getTokenFromLocalStorage } from "./constants";

export const fetchCities = async (page = 1, limit = 20, searchTerm) => {
    try {
        const countResponse = await axios.get(`${BASE_URL}/city/totalItems`, {
            params: {
                ...(searchTerm && { name: searchTerm }),
            },
            headers: {
                token: getTokenFromLocalStorage(),
            }
        });
        const totalItems = countResponse.data.totalItems;
        const response = await axios.get(`${BASE_URL}/city`, {
            params: {
                page,
                limit,
                name: searchTerm,
            },
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return { data: response.data, totalPages: Math.ceil(totalItems / limit), };
    } catch (error) {
        console.error('Error fetching cities:', error);
        return {data:[],totalPages:0};

    }
};

export const deleteCity = async (cityId) => {
    try {
        const response = await axios.delete(`${BASE_URL}/city/${cityId}`, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const addCity = async (cityData) => {
    try {
        const response = await axios.post(`${BASE_URL}/city`, cityData, {
            headers: {
                token: getTokenFromLocalStorage(),
            },
        });
        return response.data.id; // Return the id of the newly created city
    } catch (error) {
        console.error('Error adding city:', error);
        throw new Error('Failed to add city. Please try again.'); // Throw an error if adding fails
    }
};


export const updateCity = async (cityId, cityData) => {
    try {
        await axios.put(`${BASE_URL}/city/${cityId}`, cityData,
            {
                headers: {
                    token: getTokenFromLocalStorage(),
                },
            }

        );
        return true; // Return true indicating successful update
    } catch (error) {
        console.error('Error updating city:', error);
        throw new Error('Failed to update city. Please try again.'); // Throw an error if update fails
    }
};