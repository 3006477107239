import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useBgColor, useBgColorChild } from '../../../utils/colors';
import { cities, districts, users } from '../../../utils/dummyData';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { FaRegEye } from 'react-icons/fa';
import {
  BiSearch,
  BiChevronLeft,
  BiChevronRight,
  BiShow,
} from 'react-icons/bi';

import Drawers from './Drawers';
import DeleteAlert from '../../../component/DeleteAlert';
import { getCityNameById } from '../../../utils/funtions';
import { deleteCity, fetchCities } from '../../../api/cityApi';
import Loader from '../../../component/Loader/Loader';
const Layout = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Define isDrawerOpen state
  const [selectedDrawerType, setSelectedDrawerType] = useState(''); // Define selectedDrawerType state
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1); // Total pages defaults to 1

  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchCities(currentPage, itemsPerPage, searchTerm);
        setFilteredItems(data.data);
        setTotalPages(data.totalPages); // Calculate total pages

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching cities:', error);
        toast({
          title: 'Error fetching cities.',
          description: 'Failed to fetch cities. Please try again.',
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, [currentPage, searchTerm]);
  const handleSearchChange = event => {
    const searchText = event.target.value.toLowerCase();
    setSearchTerm(searchText);
  };
  const handlePageChange = newPage => {
    const validPage = Math.max(1, Math.min(newPage, totalPages)); // Ensure new page is within valid range
    setCurrentPage(validPage);
    // No need to fetch data here, useEffect will handle it based on currentPage change
  };

  const openDrawer = (drawerType, itemData) => {
    setSelectedDrawerType(drawerType);
    setSelectedItemData(itemData);
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedDrawerType('');
    setSelectedItemData(null);
  };
  const handleDeleteClick = (name, itemId) => {
    setSelectedItemId(itemId);
    setSelectedItemName(name);
    setIsDeleteAlertOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      try {
        const isDeleted = await deleteCity(selectedItemId);
        if (isDeleted) {
          toast({
            title: `${selectedItemName} deleted successfully.`,
            status: 'success',
            position: 'top-right',
            duration: 3000,
            isClosable: true,
          });
          // Remove the deleted city from the filteredItems list
          setFilteredItems(
            filteredItems.filter(item => item.id !== selectedItemId)
          );
        }
      } catch (error) {
        console.error('Error deleting city:', error);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsDeleteAlertOpen(false);
    }
  };
  const handleAddCity = async (cityData, type) => {
    if (type === 'add') {
      // Add the new city to the list
      setFilteredItems(prevCities => [...prevCities, cityData]);
      setCurrentPage(1); // Optionally reset to the first page
    } else if (type === 'edit') {
      // Update the existing city in the list
      setFilteredItems(prevCities =>
        prevCities.map(city =>
          city.id === cityData.id ? { ...city, ...cityData } : city
        )
      );
      setCurrentPage(1); // Optionally reset to the first page
    }
  };

  return (
    <Box bg={useBgColorChild} borderRadius="lg" px={2} py={2} shadow="xl">
      <Flex justify="space-between" my={4}>
        <Flex align="center" w="50%">
          <InputGroup w="100%" size={'sm'}>
            <InputLeftElement
              pointerEvents="none"
              color="gray.400"
              fontSize="1.2em"
              ml={2}
            >
              <BiSearch />
            </InputLeftElement>
            <Input
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={handleSearchChange}
              borderRadius="0.3rem"
              py={2}
              pl={10}
              pr={3}
              fontSize="md"
              mr={4}
              _placeholder={{ color: 'gray.400' }}
              bg={useBgColor}
            />
          </InputGroup>
        </Flex>
        <Button colorScheme="green" onClick={() => openDrawer('addNew')}>
          Add City
        </Button>
      </Flex>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>S.No</Th>
                  <Th>Name</Th>
                  {/* <Th>City</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {filteredItems.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{item.name}</Td>
                    {/* <Td>{getCityNameById(item.city_id)}</Td> */}
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<HiOutlineDotsVertical />}
                          variant="ghost"
                          size="sm"
                        />
                        <MenuList>
                          <MenuItem
                            icon={<FaRegEye />}
                            onClick={() => openDrawer('show', item)}
                            // as={Link}
                            // to="/branches/details"
                            // state={{ selectedItem: item }}
                          >
                            Show
                          </MenuItem>
                          <MenuItem
                            icon={<FiEdit />}
                            onClick={() => openDrawer('edit', item)}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            icon={<FiTrash2 />}
                            onClick={() =>
                              handleDeleteClick(item.name, item.id)
                            }
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
      <Flex justify="space-between" mt={4} align="center">
        <Box>
          <IconButton
            icon={<BiChevronLeft />}
            isDisabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            aria-label="Previous Page"
          />
          <IconButton
            icon={<BiChevronRight />}
            isDisabled={currentPage >= totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            ml={2}
            aria-label="Next Page"
          />
        </Box>
        <Text fontSize="smaller">
          Page {currentPage} of {totalPages}
        </Text>
      </Flex>
      <Drawers
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        drawerType={selectedDrawerType}
        data={selectedItemData}
        onSave={handleAddCity}
      />
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onConfirmDelete={handleConfirmDelete}
        HeaderText={'Delete City'}
        BodyText={`Are you sure you want to delete ${selectedItemName} ?`}
      />
    </Box>
  );
};

export default Layout;
