import { jsPDF } from 'jspdf';
import govLogo from '../images/logo/sindhLogo.png';
import depdLogo from '../images/logo/depdLogo.png';
import backgroundImage from '../images/logo/border.png';
import { beautifyDate } from './funtions';

function createPDF(data, qrCode) {
  const doc = new jsPDF({
    orientation: 'landscape', // Set the orientation to landscape
    unit: 'mm', // Set the measurement unit to millimeters
    format: 'a4', // Set the page format to A4
    compress: true, // Enable compression
  });
  doc.addFont('ComicSansMS', 'Comic Sans', 'normal');
  const width = doc.internal.pageSize.width;
  const height = doc.internal.pageSize.height;
  const name = data?.name;

  // Draw a rectangle around the entire page
  //   doc.rect(15, 15, width - 30, height - 30);
  const heading1 = 'SINDH PERSONS WITH DISABILITIES PROTECTION AUTHORITY';
  const heading2 = 'DEPARTMENT OF EMPOWERMENT OF PERSONS WITH DISABILITIES';
  const heading3 = 'GOVERNMENT OF SINDH';
  const heading4 = 'DISABILITY ASSESSMENT CERTIFICATE';
  const heading5 =
    'DISTRICT COMMITTEE/MEDICAL ASSESSMENT BOARD __________________________';
  const ent1 = '1. NAME:____________________________________________________';
  const ent2 =
    '2. S/O, D/O, W/O:____________________________________________________';
  const ent3 = '3. DATE OF BIRTH:________________________________';
  const ent4 =
    '4. SCNIC#/SNICOP#:____________________________________________________________';
  const ent5 = '5. EDUCATIONAL QUALIFICATION:_______________________';
  const ent6 = '6. DOMICILE:______________________________';
  const ent7 = '7. RELIGION:_____________________';
  const ent8 =
    '8. POSTAL ADDRESS:____________________________________________________________________________________________________________';
  const ent9 = '9. CONTACT NO.:_________________________';
  const ent10 = '10. EMAIL:';
  const ent11 = '11. TYPE OF DISABILITIES:';
  const pd = '(1) Physical Disabilities';
  const pd1 = 'Locomotor Disability';
  const pd2 = 'Cerebral palsy';
  const pd3 = 'Muscular Dystrophy';
  const pd4 = 'Spina Bifida';
  const pd5 = 'Polio';
  const pd6 = 'Dwarfism';
  const pd7 = 'Paralysis';
  const pd8 = 'Bone Deficiency';
  const pd9 = 'Muteness (by any reason)';
  const vi = '(2) Visual Impairment';
  const vi1 = 'Blindness';
  const vi2 = 'Low Vision';
  const hi = '(3) Hearing Impairment';
  const hi1 = 'Deaf';
  const hi2 = 'Hard Of Hearing';
  const hi3 = 'Late Deafened';
  const nd = '(4) Neuro - developmental Disorder';
  const nd1 = 'Intellectual Disability';
  const nd2 = 'Autism Spectrum Disorder';
  const nd3 = 'Attention Deficit Hyper Activity Disorder';
  const nd4 = 'Specific Learning Disorder';
  const nd5 = 'Communication Disorder';
  const nd6 = 'Down Syndrome';

  const cn = '(5) Disabilities Caused Due To Chronic Neurological Conditions:';
  const cn1 = 'Multiple Sclerosis';
  const cn2 = 'Parkinson’s Disease';
  const cn3 = 'Dementia';

  const md = '(6) Multiple Disabilities';

  const cd = '12. CAUSE OF DISABILITY:';
  const cd1 = 'By Birth';
  const cd2 = 'Disease';
  const cd3 = 'Accident';

  const ed = '13. Extent of Disability:______________________';

  const MS = 'MEDICAL SUPERINTENDENT /';
  const MS1 = 'CIVIL SURGEON (CHAIRMAN)';

  const SD = 'Senior Doctor /';
  const SD1 = 'Specialist (BS-18)';

  const OD = 'OFFICER DEPD (BS-18/19)';
  const OD1 = 'Designation:__________________';

  const FP = 'FOCAL PERSON';
  const FP1 = 'SDPPA, KARACHI';

  doc.setFont('helvetica', 'bold');
  let textY = 23;
  let leftPadding = 23;

  //   doc.setGState(new doc.GState({ opacity: 0.4 }));
  doc.addImage(
    backgroundImage,
    'PNG',
    5,
    5,
    width - 10,
    height - 10,
    '',
    'FAST'
  );

  // Draw a rectangle around the entire page
  //   doc.setGState(new doc.GState({ opacity: 1.0 })); // Reset opacity
  // Add headin1 and headers
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text(heading1, width / 2 - doc.getTextWidth(heading1) / 2, textY);
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(13);
  textY += 5;
  doc.text(heading2, width / 2 - doc.getTextWidth(heading2) / 2, textY);
  doc.setFontSize(14);
  textY += 5;
  doc.text(heading3, width / 2 - doc.getTextWidth(heading3) / 2, textY);

  let logoWidth = 28;
  let logoHeight = 30;
  doc.addImage(govLogo, 'PNG', 25, 22, logoWidth, logoHeight);

  logoWidth = 43;
  logoHeight = 41;
  doc.addImage(depdLogo, 'PNG', width - 66, 8, logoWidth, logoHeight);
  // Add main title
  doc.setFontSize(20);
  doc.setFont('helvetica', 'bold');
  textY += 9;
  doc.text(heading4, width / 2 - doc.getTextWidth(heading4) / 2, textY);

  // Subtitle
  doc.setFontSize(10);
  textY += 9.5;
  doc.text(heading5, width / 2 - doc.getTextWidth(heading5) / 2, textY);
  // doc.text(
  //   data?.district,
  //   width / 2 -
  //   doc.getTextWidth('DISTRICT COMMITTEE/MEDICAL ASSESSMENT BOARD__') / 2 +
  //   75,
  //   textY
  // );
  doc.setFont('helvetica', 'normal');

  // Serial No.
  textY += 12.8;
  doc.text('SERIAL NO.', leftPadding, textY);
  doc.setFontSize(16);

  doc.text(data?.serial_no, leftPadding + doc.getTextWidth('SERIAL NO.'), textY);

  doc.setFontSize(10);
  // Registration No. and Date of Issue
  textY += 6;
  doc.text(
    'REGISTRATION No:________________________________________',
    leftPadding,
    textY
  );
  doc.text(
    data?.reg_no,
    leftPadding + doc.getTextWidth('REGISTRATION No:') + 5,
    textY
  );
  doc.setFont('helvetica', 'italic');
  doc.setFontSize(8);
  doc.text(
    '(to be filled by Authority)',
    leftPadding +
    doc.getTextWidth(
      'REGISTRATION No:_______________________________________'
    ) +
    25,
    textY
  );

  doc.setFont('helvetica', 'normal');
  doc.setFontSize(10);
  textY += 7.4;
  doc.text(
    'DATE OF ISSUE:__________________________________________',
    leftPadding,
    textY
  );
  doc.text(
    beautifyDate( data?.date_of_issue,false),
    leftPadding + doc.getTextWidth('DATE OF ISSUE:') + 5,
    textY
  );
  doc.setFont('helvetica', 'italic');
  doc.setFontSize(8);
  doc.text(
    '(to be filled by Authority)',
    leftPadding +
    doc.getTextWidth(
      'REGISTRATION No:_______________________________________'
    ) +
    25,
    textY
  );

  //   Add a placeholder for the photograph
  doc.rect(width - 55, 40, 25, 35);
  doc.text('Photograph', width - 50, 55);
  doc.text('35mm x 45mm', width - 52, 60);

  //Qr Code
  //   doc.rect(width - 90, 55, 25, 25);
  doc.addImage(qrCode, 'PNG', width - 90, 55, 25, 25, '', 'FAST');

  // Form Fields
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(10);
  textY += 7.4;
  doc.text(ent1, leftPadding, textY);
  doc.text(data?.name, leftPadding + doc.getTextWidth('1. NAME:') + 5, textY);
  doc.text(ent2, leftPadding + doc.getTextWidth(ent1) + 2.4, textY);
  doc.text(
    data?.childO,
    leftPadding +
    doc.getTextWidth('2. S/O, D/O, W/O:') +
    doc.getTextWidth(ent1) +
    5,
    textY
  );

  textY += 7.4;
  doc.text(ent3, leftPadding, textY);
  doc.text(
    beautifyDate(data?.date_of_birth,false),
    leftPadding + doc.getTextWidth('3. DATE OF BIRTH:') + 5,
    textY
  );
  doc.text(ent4, leftPadding + doc.getTextWidth(ent3) + 3.2, textY);
  doc.text(
    data?.cnic,
    leftPadding +
    doc.getTextWidth('4. SCNIC#/SNICOP#:') +
    doc.getTextWidth(ent3) +
    5,
    textY
  );

  textY += 7.4;
  doc.text(ent5, leftPadding, textY);
  doc.text(
    data?.education,
    leftPadding + doc.getTextWidth('5. EDUCATIONAL QUALIFICATION:') + 5,
    textY
  );
  doc.text(ent6, leftPadding + doc.getTextWidth(ent5) + 1.4, textY);
  doc.text(
    data?.domicile,
    leftPadding + doc.getTextWidth('6. DOMICILE:') + doc.getTextWidth(ent5) + 5,
    textY
  );

  doc.text(
    ent7,
    leftPadding + doc.getTextWidth(ent5) + doc.getTextWidth(ent6) + 2.8,
    textY
  );
  doc.text(
    data?.religion,
    leftPadding +
    doc.getTextWidth('7. RELIGION:') +
    doc.getTextWidth(ent5) +
    doc.getTextWidth(ent6) +
    5,
    textY
  );

  textY += 7.4;
  doc.text(ent8, leftPadding, textY);
  doc.text(
    data?.address,
    leftPadding + doc.getTextWidth('8. POSTAL ADDRESS:') + 5,
    textY
  );

  textY += 7.4;
  doc.text(ent9, leftPadding, textY);
  doc.text(
    data?.contactNo,
    leftPadding + doc.getTextWidth('9. CONTACT NO.:') + 5,
    textY
  );
  doc.text(ent10, leftPadding + doc.getTextWidth(ent9) + 2.6, textY);
  doc.text(
    data?.email,
    leftPadding + doc.getTextWidth('10. EMAIL:') + doc.getTextWidth(ent9) + 5,
    textY
  );

  // Type of Disabilities
  doc.setFillColor(0, 0, 0);
  textY += 7;
  let extraPadding = 0;
  doc.text(ent11, leftPadding, textY);
  doc.text(pd, leftPadding + doc.getTextWidth(ent11) + 3, textY);
  extraPadding +=
    25.4 + leftPadding + doc.getTextWidth(ent11) + doc.getTextWidth(ent10) + 3;
  {
    data?.pd1 === true
      ? doc.rect(extraPadding, textY - 2, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2, 3.6, 2.6);
  }

  //   doc.rect(extraPadding, textY - 2, 3.6, 2.6, 'F');
  doc.setFont('helvetica', 'normal');
  textY += 0.4;
  doc.text(pd1, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(pd1) + 9;
  {
    data?.pd2 === true
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  //   doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  doc.text(pd2, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(pd2) + 7;
  {
    data?.pd3
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(pd3, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(pd3) + 9;
  {
    data?.pd4
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(pd4, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(pd4) + 9;
  {
    data?.pd5
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(pd5, extraPadding + 5, textY);

  extraPadding = 28;
  textY += 4.6;
  {
    data?.pd6
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(pd6, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(pd6) + 8;
  {
    data?.pd7
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(pd7, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(pd7) + 7.4;
  {
    data?.pd8
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(pd8, extraPadding + 4.8, textY);

  extraPadding += doc.getTextWidth(pd8) + 8.4;
  {
    data?.pd9
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(pd9, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(pd9) + 11;
  doc.setFont('helvetica', 'bold');
  doc.text(vi, extraPadding, textY);

  doc.setFont('helvetica', 'normal');
  extraPadding += doc.getTextWidth(vi) + 7;
  {
    data?.vi1
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(vi1, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(vi1) + 8.4;
  {
    data?.vi2
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(vi2, extraPadding + 5, textY);

  extraPadding = 23;
  textY += 5;
  doc.setFont('helvetica', 'bold');
  doc.text(hi, extraPadding, textY);

  doc.setFont('helvetica', 'normal');
  extraPadding += doc.getTextWidth(hi) + 5;
  {
    data?.hi1
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(hi1, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(hi1) + 8;
  {
    data?.hi2
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(hi2, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(hi2) + 8.4;
  {
    data?.hi3
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(hi3, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(hi3) + 12;
  doc.setFont('helvetica', 'bold');

  doc.text(nd, extraPadding, textY);

  doc.setFont('helvetica', 'normal');
  extraPadding += doc.getTextWidth(nd) + 8.4;
  {
    data?.nd1
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(nd1, extraPadding + 5, textY);

  extraPadding = 28;
  textY += 5.2;
  {
    data?.nd2
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(nd2, extraPadding + 4, textY);

  extraPadding += doc.getTextWidth(nd2) + 8.4;
  {
    data?.nd3
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(nd3, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(nd3) + 9.4;
  {
    data?.nd4
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(nd4, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(nd4) + 8;
  {
    data?.nd5
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(nd5, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(nd5) + 8.8;
  {
    data?.nd6
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(nd6, extraPadding + 5, textY);

  doc.setFont('helvetica', 'bold');
  extraPadding = 23;
  textY += 5.2;
  doc.text(cn, extraPadding, textY);

  doc.setFont('helvetica', 'normal');
  extraPadding += doc.getTextWidth(cn) + 10;
  {
    data?.cn1
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(cn1, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(cn1) + 10;
  {
    data?.cn2
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(cn2, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(cn2) + 10;
  {
    data?.cn3
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(cn3, extraPadding + 5, textY);

  doc.setFont('helvetica', 'bold');
  extraPadding += doc.getTextWidth(cn3) + 8;
  doc.text(md, extraPadding, textY);

  extraPadding = 23;
  textY += 5.2;
  doc.text(cd, extraPadding, textY);

  doc.setFont('helvetica', 'normal');
  extraPadding += doc.getTextWidth(cd) + 5.6;
  {
    data?.cd1
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(cd1, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(cd1) + 7.4;
  {
    data?.cd2
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(cd2, extraPadding + 5, textY);

  extraPadding += doc.getTextWidth(cd2) + 7.4;
  {
    data?.cd3
      ? doc.rect(extraPadding, textY - 2.4, 3.6, 2.6, 'F')
      : doc.rect(extraPadding, textY - 2.4, 3.6, 2.6);
  }
  doc.text(cd3, extraPadding + 5, textY);

  doc.setFont('helvetica', 'bold');
  extraPadding += doc.getTextWidth(cd3) + 15;
  doc.text(ed, extraPadding, textY);

  // Signatures and Designations
  textY += 26;
  leftPadding = 27;
  doc.setFontSize(11);
  doc.text(MS, leftPadding, textY);
  leftPadding += 29 + doc.getTextWidth(MS);
  doc.text(SD, leftPadding, textY);
  leftPadding += 23 + doc.getTextWidth(SD);
  doc.text(OD, leftPadding, textY);
  leftPadding = width - 30 - doc.getTextWidth(FP);
  textY = height - 25;
  doc.setFontSize(14);
  doc.text(FP, leftPadding, textY);

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(9);
  textY -= 8;
  leftPadding = 32;
  doc.text(MS1, leftPadding, textY);
  leftPadding += 35 + doc.getTextWidth(MS1);
  doc.text(SD1, leftPadding, textY);
  leftPadding += 23 + doc.getTextWidth(SD1);
  doc.text(OD1, leftPadding, textY + 3.8);
  leftPadding = width - 28 - doc.getTextWidth(FP1);
  textY = height - 21.4;
  doc.text(FP1, leftPadding, textY);

  // Save the PDF
  //   doc.save('Disability_Assessment_Certificate.pdf');
  return doc;
}

export const generateCertificate = (data, qrCode) => {
  const doc = createPDF(data, qrCode);
  doc.save('Test');
};

export const printPdf = (data, qrCode) => {
  const doc = createPDF(data, qrCode);

  // Create a Blob from the PDF
  const pdfBlob = doc.output('blob');

  // Create a URL for the Blob
  const pdfUrl = URL.createObjectURL(pdfBlob);

  // Open a new window for printing
  const printWindow = window.open(pdfUrl, '_blank');

  // Wait for the PDF to load before printing
  printWindow.onload = () => {
    printWindow.print();
    // Clean up after printing
    // URL.revokeObjectURL(pdfUrl);
  };

  return pdfBlob; // Return the PDF blob
};


export const returnPdf = (data, qrCode) => {
  const doc = createPDF(data, qrCode);

  // Create a Blob from the PDF
  const pdfBlob = doc.output('blob');

  return pdfBlob; // Return the PDF blob
};



